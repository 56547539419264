import React from "react";
import { useEffect, useState } from "react";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";
import Select from "react-select";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { FilterIcon } from "../svgIcons/svgIcons";
import TablePlayer from "./TablePlayer";
import { convertAmount } from "../../Utils/CurrencyUtils";

const sortingObj = {
    Blind: false,
    BuyIn: false,
    Players: false,
};

const HoldemGamesTableComponent = () => {
    const [holdemGamesList, setHoldemGamesList] = useState([]);
    const [holdemGamesListHtml, setHoldemGamesListHtml] = useState([]);
    const [gameTypeOptions, setGameTypeOptions] = useState([]);
    const [filterGameType, setFilterGameType] = useState(null);
    const [blindOptions, setBlindOptions] = useState([]);
    const [filterBlind, setFilterBlind] = useState(null);
    const [playersOptions, setPlayersOptions] = useState([]);
    const [filterPlayer, setFilterPlayer] = useState(null);
    const [tableTypeOptions, setTableTypeOptions] = useState([]);
    const [filterTableType, setFilterTableType] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        gameType: "",
        blind: "",
        players: "",
        table: "",
    });
    const [sortingState, setSortingState] = useState(sortingObj);
    const [filterList, setFilterList] = useState([]);
    const [selectedTab, setSelectedTab] = useState("BluffGame");

    const navigate = useNavigate();

    useEffect(() => {
        //   "BluffGame" || "SingleHandGame" || "DoubleHandGame" || gameType
        const dummyArr = [...holdemGamesList]
        const _res = dummyArr.filter((item) => item.gameType === selectedTab);
        setFilterList(_res);
    }, [selectedTab])
    

    const drawTableOnHtml = (dict) => {
        var tempFilterTable = [...holdemGamesList];
        var filteredDataTable = [];

        tempFilterTable.map((game) => {
            if (
                (dict.gameType === "" || (dict.gameType !== "" && game.gameType === dict.gameType)) &&
                (dict.blind === "" || (dict.blind !== "" && game.smallBlind + "-" + game.bigBlind === dict.blind))
            ) {
                filteredDataTable.push(game);
            }
        });
        var tempArray = [];
        filteredDataTable.map((game, index) => {
            // var monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // var date = new Date(game.startTime).getDate() + " " + monthArray[new Date(game.startTime).getMonth() + 1] + " " + new Date(game.startTime).getFullYear();
            // var time = new Date(game.startTime)
            var hours = new Date(game.startTime).getHours();
            var minutes = new Date(game.startTime).getMinutes();
            var ampm = hours >= 12 ? "pm" : "am";
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? "0" + minutes : minutes;
            // var strTime = hours + ':' + minutes + ' ' + ampm;
            // var time = strTime;
            // var tableStatus = game.joined;
            // var runningTableId = game.tableId;
            var html = (
                <tr key={index}>
                    <td className="text-left">
                        <span className="table-img-box">{game.gameType}</span>
                    </td>
                    <td>{game.gameVariation}</td>
                    <td>{game.tableName}</td>
                    <td>{parseInt(game.smallBlind) + "-" + parseInt(game.bigBlind)}</td>
                    <td>{parseInt(game.minimumBuyIn) + "-" + parseInt(game.maximumBuyIn)}</td>
                    <td>
                        {game.turnTimeTitle}({game.turnTime} / {game.graceTime})
                    </td>
                    <td>{game.playingPlayers + "/" + game.maxPlayers}</td>
                    <td>{game.tableStatus}</td>
                </tr>
            );
            tempArray.push(html);
        });
        // }
        setHoldemGamesListHtml(tempArray);
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            color: state.selectProps.menuColor,
        }),

        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
    };

    const handleGameTableTypeFilterChange = (e) => {
        setFilterGameType(e);
        setFilterOptions({
            ...filterOptions,
            gameType: e === null ? "" : e.value,
        });
        var dict = {
            gameType: e === null ? "" : e.value,
            gameVariation: filterOptions.gameVariation,
            blind: filterOptions.blind,
            players: filterOptions.players,
        };
        drawTableOnHtml(dict);
    };
    const handleBlindFilterChange = (e) => {
        setFilterBlind(e);
        setFilterOptions({
            ...filterOptions,
            blind: e === null ? "" : e.value,
        });
        var dict = {
            gameType: filterOptions.gameType,
            gameVariation: filterOptions.gameVariation,
            blind: e === null ? "" : e.value,
            players: filterOptions.players,
        };
        drawTableOnHtml(dict);
    };

    const handlePlayerFilterChange = (e) => {
        setFilterPlayer(e);
        setFilterOptions({
            ...filterOptions,
            players: e === null ? "" : e.value,
        });
        var dict = {
            gameType: filterOptions.gameType,
            gameVariation: filterOptions.gameVariation,
            blind: filterOptions.blind,
            players: e === null ? "" : e.value,
        };
        drawTableOnHtml(dict);
    };

    const handleTableTypeFilterChange = (e) => {
        setFilterTableType(e);
        setFilterOptions({
            ...filterOptions,
            gameVariation: e === null ? "" : e.value,
        });
        var dict = {
            gameType: filterOptions.gameType,
            gameVariation: e === null ? "" : e.value,
            blind: filterOptions.blind,
            players: filterOptions.players,
        };
        drawTableOnHtml(dict);
    };

    var playerData = new Cookies().get("pokerLoginData");
    var playerLoggedIn = false;
    useEffect(() => {
        playerLoggedIn = false;
        if (playerData !== undefined) {
            playerLoggedIn = true;
        }
        getTableList(playerLoggedIn);
    }, []);

    const getTableList = (playerLoggedIn) => {
        ApiCaller.callGetApi(
            url.MethodTableList,
            {},
            (response) => {
                setHoldemGamesList(response.responsePacket);
                const _res = response?.responsePacket?.filter((item) => item.gameType === "BluffGame");
                setFilterList(_res);
                var tempGameTableType = [];
                var tempGameTableTypeSame = false;

                var tempBlind = [];
                var tempBlindSame = false;

                var tempPlayers = [];
                var tempPlayersSame = false;

                var tempTableType = [];
                var tempTableTypeSame = false;

                response.responsePacket.map((game) => {
                    //Game Table Type Filter
                    if (tempGameTableType.length === 0) {
                        tempGameTableType.push(game.gameType);
                    } else {
                        for (let i = 0; i < tempGameTableType.length; i++) {
                            if (tempGameTableType[i] !== game.gameType) {
                                tempGameTableTypeSame = false;
                            } else {
                                tempGameTableTypeSame = true;
                                break;
                            }
                        }
                        if (!tempGameTableTypeSame) {
                            tempGameTableType.push(game.gameType);
                        }
                    }
                    //Blind Filter
                    if (tempBlind.length === 0) {
                        tempBlind.push(game.smallBlind + "-" + game.bigBlind);
                    } else {
                        for (let i = 0; i < tempBlind.length; i++) {
                            if (tempBlind[i] !== game.smallBlind + "-" + game.bigBlind) {
                                tempBlindSame = false;
                            } else {
                                tempBlindSame = true;
                                break;
                            }
                        }

                        if (!tempBlindSame) {
                            tempBlind.push(game.smallBlind + "-" + game.bigBlind);
                        }
                    }
                    //Players Filter
                    if (tempPlayers.length === 0) {
                        tempPlayers.push(game.maxPlayers);
                    } else {
                        for (let i = 0; i < tempPlayers.length; i++) {
                            if (tempPlayers[i] !== game.maxPlayers) {
                                tempPlayersSame = false;
                            } else {
                                tempPlayersSame = true;
                                break;
                            }
                        }

                        if (!tempPlayersSame) {
                            tempPlayers.push(game.maxPlayers);
                        }
                    }
                    //Table Type Filter
                    if (tempTableType.length === 0) {
                        tempTableType.push(game.gameVariation);
                    } else {
                        for (let i = 0; i < tempTableType.length; i++) {
                            if (tempTableType[i] !== game.gameVariation) {
                                tempTableTypeSame = false;
                            } else {
                                tempTableTypeSame = true;
                                break;
                            }
                        }
                        if (!tempTableTypeSame) {
                            tempTableType.push(game.gameVariation);
                        }
                    }

                    const tempGameTypeOptions = response.responsePacket.map((d) => ({
                        value: d.gameType,
                        label: d.gameType,
                    }));
                    setGameTypeOptions(tempGameTypeOptions);

                    const tempBlindOptions = response.responsePacket.map((d) => ({
                        value: d.smallBlind + "-" + d.bigBlind,
                        label: d.smallBlind + "-" + d.bigBlind,
                    }));
                    setBlindOptions(tempBlindOptions);

                    const tempPlayersOptions = response.responsePacket.map((d) => ({
                        value: d.maxPlayers,
                        label: d.maxPlayers,
                    }));
                    setPlayersOptions(tempPlayersOptions);

                    const tempTableTypeOptions = response.responsePacket.map((d) => ({
                        value: d.gameVariation,
                        label: d.gameVariation,
                    }));
                    setTableTypeOptions(tempTableTypeOptions);
                });

                var tempArray = [];
                response.responsePacket.map((game, index) => {
                    var html = (
                        <tr key={index}>
                            <td className="text-left">
                                <span className="table-img-box">{game.gameType}</span>
                            </td>
                            <td>{game.gameVariation}</td>
                            <td>{game.tableName}</td>
                            <td>{parseInt(game.smallBlind) + "-" + parseInt(game.bigBlind)}</td>
                            <td>{parseInt(game.minimumBuyIn) + "-" + parseInt(game.maximumBuyIn)}</td>
                            <td>
                                {game.turnTimeTitle}({game.turnTime} / {game.graceTime})
                            </td>
                            <td>{game.playingPlayers + "/" + game.maxPlayers}</td>
                            <td>{game.tableStatus}</td>
                            <td className="text-right">
                                {/* <a href="#" className="custom-btn btn-sm-w-h btn text-white" style={{ display: playerLoggedIn ? "block" : "none" }}
                            onClick={() => handleJoinGameAction(game)}
                        >Joined</a> */}
                                <Link className="custom-btn btn-sm-w-h btn text-white" style={{ display: playerLoggedIn ? "block" : "none" }} to={getGamePlayUrl(game)}>
                                    Join
                                </Link>
                            </td>
                        </tr>
                    );
                    tempArray.push(html);
                });
                setHoldemGamesListHtml(tempArray);
            },
            (error) => {
                console.error("FAILED : ", error);
            }
        );
    };

    const handleJoinGameAction = (game) => {
        // let gameUrl = "";
        // let tableId = "";
        // if (game.gameType !== "DoubleHandGame") {
        //     gameUrl = "gamePlay";
        //     tableId = game.runningGameId;
        // } else /* if (game.gameType === "NormalGame") */ {
        //     gameUrl = "gamePlayDoubleHand";
        //     tableId = game.doubleHandGameId;
        // }
        // // "/" + gameUrl + "?ClientId=&GameType=" + game.gameType + "&TableId=" + tableId + "&U=" + playerData.username + "&S=" + playerData.uniqueKey;
        let gamePlayUrl = getGamePlayUrl(game);
        window.open(gamePlayUrl, "_blank");
    };

    const getGamePlayUrl = (game) => {
        let gameUrl = "";
        let tableId = "";
        if (game.gameType !== "DoubleHandGame") {
            gameUrl = "gamePlay";
            tableId = game.runningGameId;
        } /* if (game.gameType === "NormalGame") */ else {
            gameUrl = "gamePlayDoubleHand";
            tableId = game.doubleHandGameId;
        }
        if (playerLoggedIn) {
            let gamePlayUrl = "/" + gameUrl + "?ClientId=&GameType=" + game.gameType + "&TableId=" + tableId + "&U=" + playerData.username + "&S=" + playerData.uniqueKey;
            return gamePlayUrl;
        } else {
            return "/";
        }
    };

    const joinTable = (game) => {
        console.log(game);
        let gameUrl = "";
        let tableId = "";
        if (game.gameType !== "DoubleHandGame") {
            gameUrl = "gamePlay";
            tableId = game.runningGameId;
        } /* if (game.gameType === "NormalGame") */ else {
            gameUrl = "gamePlayDoubleHand";
            tableId = game.doubleHandGameId;
        }
        console.log(playerLoggedIn)
        if (true) {
            let gamePlayUrl = "/" + gameUrl + "?ClientId=&GameType=" + game.gameType + "&TableId=" + tableId + "&U=" + playerData.username + "&S=" + playerData.uniqueKey;
            navigate(gamePlayUrl);
        } else {
            navigate("/");
        }
    }

    const sortList = (value) => {
        let newFilterList = [...holdemGamesList];
        if (value === "Blind") {
            if (sortingState.Blind) {
                newFilterList.sort((a, b) => b.bigBlind - a.bigBlind);
                setSortingState((prev) => ({ ...prev, ["Blind"]: false }));
            } else {
                newFilterList.sort((a, b) => a.bigBlind - b.bigBlind);
                setSortingState((prev) => ({ ...prev, ["Blind"]: true }));
            }
        } else if (value === "BuyIn") {
            if (sortingState.BuyIn) {
                newFilterList.sort((a, b) => b.maximumBuyIn - a.maximumBuyIn);
                setSortingState((prev) => ({ ...prev, ["BuyIn"]: false }));
            } else {
                newFilterList.sort((a, b) => a.maximumBuyIn - b.maximumBuyIn);
                setSortingState((prev) => ({ ...prev, ["BuyIn"]: true }));
            }
        } else if (value === "Players") {
            if (sortingState.Players) {
                newFilterList.sort((a, b) => b.maxPlayers - a.maxPlayers);
                setSortingState((prev) => ({ ...prev, ["Players"]: false }));
            } else {
                newFilterList.sort((a, b) => a.maxPlayers - b.maxPlayers);
                setSortingState((prev) => ({ ...prev, ["Players"]: true }));
            }
        }
        setFilterList(newFilterList);
    };

    return (
        <>
            <section className="pt-5 holdem-table masthead mt-85 desktopDiv">
                <div className="container">
                    <h2 className="text-white text-center"> Games</h2>
                    <div className="row mt-3">
                        <div className="col-md-3 col-sm-6">
                            Game Type:
                            <Select options={gameTypeOptions} onChange={handleGameTableTypeFilterChange} value={filterGameType} styles={customStyles} menuColor="black" />
                        </div>
                        <div className="col-md-3 col-sm-6">
                            Table Type:
                            <Select options={tableTypeOptions} onChange={handleTableTypeFilterChange} value={filterTableType} styles={customStyles} menuColor="black" />
                        </div>
                        <div className="col-md-3 col-sm-6">
                            Blind:
                            <Select options={blindOptions} onChange={handleBlindFilterChange} value={filterBlind} styles={customStyles} menuColor="black" />
                        </div>
                        <div className="col-md-3 col-sm-6">
                            Players:
                            <Select options={playersOptions} onChange={handlePlayerFilterChange} value={filterPlayer} styles={customStyles} menuColor="black" />
                        </div>
                    </div>
                    <div className=" mt-2 table-responsive">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Game Type</th>
                                    <th scope="col">Table Type</th>
                                    <th scope="col">Table Name</th>
                                    <th scope="col">Blind</th>
                                    <th scope="col">BuyIn</th>
                                    <th scope="col">Game Speed</th>
                                    <th scope="col">Player</th>
                                    <th scope="col">Table Status</th>
                                    <th scope="col" />
                                </tr>
                            </thead>
                            <tbody className="text-white">{holdemGamesListHtml}</tbody>
                        </table>
                    </div>
                </div>
            </section>
            <div className="mobileDiv" style={{ paddingTop: "48px" }}>
                <div className="container">
                    <div>
                        <ul className="cash-btn">
                            <li className={`nav-item border-gradient-purple border-gradient rounded ${selectedTab === "BluffGame" ? "active" : ""}`} onClick={() => setSelectedTab("BluffGame")}>
                                <span className="nav-link cursor-pointer text-uppercase">Bluff</span>
                            </li>
                            <li className={`nav-item border-gradient-purple border-gradient rounded ${selectedTab === "SingleHandGame" ? "active" : ""}`} onClick={() => setSelectedTab("SingleHandGame")}>
                                <span className="nav-link cursor-pointer text-uppercase">Single Hand Game</span>
                            </li>
                            <li className={`nav-item border-gradient-purple border-gradient rounded ${selectedTab === "DoubleHandGame" ? "active" : ""}`} onClick={() => setSelectedTab("DoubleHandGame")}>
                                <span className="nav-link cursor-pointer text-uppercase">Two Hand</span>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-btn border-gradient-purple border-gradient px-0">
                        <ul className="filter-box">
                            <li className="nav-item" onClick={() => sortList("Blind")}>
                                <span className="nav-link">
                                    Blind
                                    <span className="ml-2">
                                        <FilterIcon />
                                    </span>
                                </span>
                            </li>
                            <li className="nav-item" onClick={() => sortList("BuyIn")}>
                                <span className="nav-link">
                                    BUY-IN
                                    <span className="ml-2">
                                        <FilterIcon />
                                    </span>
                                </span>
                            </li>
                            <li className="nav-item ">
                                <span className="nav-link" onClick={() => sortList("Players")}>
                                    PLAYERS
                                    <span className="ml-2">
                                        <FilterIcon />
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="scroll-content">
                        <ul className="mt-1 texas-holdem-box ">
                            {filterList.map((item, i) => (
                                <li key={i} className="cursor-pointer rounded ">
                                    <div className="d-flex align-items-center">
                                        <div className="d-block w-100">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="col-4 p-0">
                                                    <h6 className="fs-12 text-yellow mb-0">{item.tableName}</h6>
                                                </div>
                                                <div className="col-4 p-0 d-flex justify-content-end ">
                                                    <div className="d-flex align-items-center">
                                                        <TablePlayer gameDetail={item} />
                                                    </div>
                                                </div>
                                                <div className="ribbon-div">
                                                    <span className="arrow-ribbon">{item.gameVariation}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="d-flex align-items-center">
                                                    <p className="fs-10 text-white mb-0">
                                                        Blind:
                                                        {/* <span className="text-yellow ml-1 fs-12">₪</span> */}
                                                        <span className="text-yellow ml-1">
                                                            {convertAmount(item.smallBlind)}/{convertAmount(item.bigBlind)}
                                                        </span>
                                                    </p>
                                                    <p className="fs-10 text-white mb-0 ml-2">
                                                        Buy-in:
                                                        {/* <span className="text-yellow ml-1 fs-12">₪</span> */}
                                                        <span className="text-yellow ml-1">
                                                            {convertAmount(item.minimumBuyIn)}/{convertAmount(item.maximumBuyIn)}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <button className="btn custom-btn rounded-pill fw-600 fs-10 py-1 px-3 text-white" onClick={() => joinTable(item)}>
                                                            Join
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HoldemGamesTableComponent;
