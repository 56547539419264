import React, { useCallback, useState } from 'react'
import IndexComponent from '../Pages/IndexComponent';
import LoginComponent from '../Pages/LoginComponent';
import ManageProfile from './ManageProfile';
import AboutUsComponent from '../Pages/AboutUsComponent';
import LearnComponent from '../Pages/LearnComponent';
import PrivacyPolicyComponent from '../Pages/PrivacyPolicyComponent';
import TermsConditionComponent from '../Pages/TermsConditionComponent';
import FAQComponent from '../Pages/FAQComponent';
import HoldemGamesTableComponent from './HoldemGamesTableComponent';
import GamePlay from '../GamePlay/GamePlay';
import { Route, Routes } from 'react-router-dom';
import NavigationComponent from './NavigationComponent';
import FooterComponent from './FooterComponent';
import GamePlaySingleHand from '../GamePlaySingleHand/GamePlaySingleHand';
import GamePlayDoubleHand from '../GamePlayDoubleHand/GamePlayDoubleHand';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainContentComponent = (props) => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [index, setIndex] = useState(0);
    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
        setIndex((prev) => prev + 1);
    }, [refreshFlag]);
    

    const getComponentByPath = (path) => {
        if (path === "/") {
            return <IndexComponent />;
        } else if (path === "/login") {
            return <LoginComponent />;
        } else if (path === "/profile") {
            return <ManageProfile handleRefresh={handleRefresh} />;
        } else if (path === "/about-us") {
            return <AboutUsComponent />;
        } else if (path === "/learn") {
            return <LearnComponent />;
        } else if (path === "/privacy-policy") {
            return <PrivacyPolicyComponent />;
        } else if (path === "/term-conditions") {
            return <TermsConditionComponent />;
        } else if (path === "/faq") {
            return <FAQComponent />;
        } else if (path === "/holdem-games") {
            return <HoldemGamesTableComponent />;
        } else {
            return <></>;
        }
    }
    const getElementWithHeaderFooter = (path) => {
        return (
            <>
                <NavigationComponent index={index} />
                {
                    getComponentByPath(path)
                }
                <FooterComponent />
            </>
        );
    }
    return (
        <>
            <Routes>
                <Route path="/" element={getElementWithHeaderFooter("/")} />
                <Route path="/login" element={getElementWithHeaderFooter("/login")} />
                <Route path="/profile" element={getElementWithHeaderFooter("/profile")} />
                <Route path="/about-us" element={getElementWithHeaderFooter("/about-us")} />
                <Route path="/learn" element={getElementWithHeaderFooter("/learn")} />
                <Route path="/privacy-policy" element={getElementWithHeaderFooter("/privacy-policy")} />
                <Route path="/term-conditions" element={getElementWithHeaderFooter("/term-conditions")} />
                <Route path="/faq" element={getElementWithHeaderFooter("/faq")} />
                <Route path="/holdem-games" element={getElementWithHeaderFooter("/holdem-games")} />
                <Route path='/gamePlay' element={<GamePlaySingleHand />} />
                <Route path='/gamePlayDoubleHand' element={<GamePlayDoubleHand />} />
                {/* <Route path='/gamePlay' element={<GamePlay />} /> */}
            </Routes>
            <ToastContainer />
        </>
    )
}

export default MainContentComponent