import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import HeaderBar from "../GamePlay/HeaderBar";
import Table from "./TableSingleHand";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";
import UrlUtils from "../GamePlay/Utilities/UrlUtils";
import FaceSuiteUtils from "../GamePlay/Utilities/FaceSuiteUtils";
import SocketClient from "../GamePlay/Utilities/SocketClient";
import $ from "jquery";
import SocketCaller from "../GamePlay/Utilities/SocketCaller";
import * as url from "../../Const";
import ApiCaller from "../../Api/ApiCaller";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactDOM from "react-dom/client";
import AgoraRTC from "agora-rtc-sdk-ng";

const GamePlay = () => {
    const urlUtils = new UrlUtils();
    var urlParamClientId = urlUtils.getUrlParameter("ClientId");
    var urlParamTableId = urlUtils.getUrlParameter("TableId");
    var urlParamUserName = urlUtils.getUrlParameter("U");
    var urlParamSecret = urlUtils.getUrlParameter("S");
    var urlParamUserType = urlUtils.getUrlParameter("UT");

    const appId = "d49fe6b0ca3549c7990838c55d7462e0";
    const token = null;
    const [agoraClient, setAgoraClient] = useState(null);
    const initializeAgora = async () => {
        const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        try {
            const userId = await client.join(appId, urlParamTableId, token, parseInt(loggedInPlayer.playerId));
            setAgoraClient(client);

            client.on("user-published", async (user, mediaType) => {
                // Subscribe to a remote user
                await client.subscribe(user, mediaType).then(() => {
                    if (user.uid !== parseInt(loggedInPlayer.playerId)) {
                        if (mediaType === "video") {
                            // Get `RemoteVideoTrack` in the `user` object.
                            const remoteVideoTrack = user.videoTrack;
                            // // Dynamically create a container in the form of a DIV element for playing the remote video track.
                            const root = ReactDOM.createRoot(document.getElementById(`remote-stream-${user.uid}`));
                            let videoDiv = React.createElement("div", {
                                id: "video-div-" + user.uid,
                                className: "remote-stream",
                            });
                            root.render(videoDiv);
                            setTimeout(function () {
                                user.videoTrack.play(`video-div-${user.uid}`);
                            }, 2000);
                        }
                        if (mediaType === "audio") {
                            // Get `RemoteAudioTrack` in the `user` object.
                            const remoteAudioTrack = user.audioTrack;
                            // Play the audio track. Do not need to pass any DOM element
                            remoteAudioTrack.play();
                        }
                    }
                });
            });
            // client.on("user-joined", async (user) => {
            //     // Subscribe to a remote user
            //     console.log("3.0 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", user);
            // });
            // client.on("user-unpublished", async (user) => {
            //     console.log("4.0 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", user);
            // });
            // return () => {
            //     handleLeave();
            // };
        } catch (error) {
            console.error("Error initializing client:", error);
        }
    };
    // const handleLeave = async () => {
    //     try {
    //         await agoraClient.leave();
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [joined, setJoined] = useState(false);

    const playAndPublishLocalVideo = async () => {
        if (!joined || agoraClient === null || agoraClient === undefined) {
            return;
        }
        try {
            let lAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            let lVideoTrack = await AgoraRTC.createCameraVideoTrack();
            lVideoTrack.play("local-stream");
            setLocalAudioTrack(lAudioTrack);
            setLocalVideoTrack(lVideoTrack);
            // // Publish the local audio and video tracks to the channel
            await agoraClient.publish([lAudioTrack, lVideoTrack]);
        } catch (err) {
            console.log(err);
        }
    };

    const stopAndUnPublishLocalVideo = async () => {
        if (localAudioTrack === null || localAudioTrack === undefined) {
            return;
        }
        try {
            // const localContainer = document.getElementById("local-stream");
            localAudioTrack.close();
            localVideoTrack.close();
            await agoraClient.leave();
            initializeAgora();
            // localContainer.textContent = "";

            // // Traverse all remote users
            // client.remoteUsers.forEach((user) => {
            //     // Destroy the dynamically created DIV container
            //     const playerContainer = document.getElementById(user.uid);
            //     playerContainer && playerContainer.remove();
            // });
            // // Leave the channel
            //
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (joined) {
            playAndPublishLocalVideo();
        } else {
            stopAndUnPublishLocalVideo();
        }
    }, [joined]);

    useEffect(() => {
        playAndPublishLocalVideo();
    }, [agoraClient]);

    const [loggedInPlayer, setLoggedInPlayer] = useState(null);
    useEffect(() => {
        if (loggedInPlayer !== null && loggedInPlayer !== undefined) {
            initializeAgora();
        }
    }, [loggedInPlayer]);

    const [gpTableModel, setGpTableModel] = useState(null);
    const [socketCaller, setSocketCaller] = useState(null);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [currencySlider01, setCurrencySlider01] = useState(0);
    const [currencySlider02, setCurrencySlider02] = useState(0);

    const faceSuiteUtils = new FaceSuiteUtils();
    const chipsUtils = new ChipsUtils();

    const [bluffGameList, setBluffGameList] = useState(null);
    const [selectedBluffGameList, setSelectedBluffGameList] = useState([]);
    const [selectedGameFromBluffGame, setSelectedGameFromBluffGame] = useState({
        value: null,
        label: "No Game",
    });
    const [selectedBluffGameListWithRaise, setSelectedBluffGameListWithRaise] = useState(null);
    //const [bluffMaxAmount, setBluffMaxAmount] = useState(0);
    const [bluffWinLoose, setBluffWinLoose] = useState("");
    const [selectedBluffType, setSelectedBluffType] = useState("BLUFF_1");
    const [canIBluff, setCanIBluff] = useState(false);
    const [action, setAction] = useState("");

    const minBluffGameSelection = 2;
    const maxBluffGameSelection = 2;

    const bluffGameListStyles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: "#3498db" } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 } : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: "none" } : base;
        },
    };

    const getBluffGameList = () => {
        ApiCaller.callGetApi(
            url.MethodGetBluffGameList,
            {},
            (response) => {
                var optionalBluffGameListTemp = [];
                response.responsePacket.map((game) => {
                    if (game.mustChosen) {
                        optionalBluffGameListTemp.push({
                            value: game.recordId,
                            label: game.gameTitle,
                            isFixed: game.mustChosen,
                        });
                    } else {
                        optionalBluffGameListTemp.push({
                            value: game.recordId,
                            label: game.gameTitle,
                        });
                    }
                    // }
                });

                setBluffGameList(optionalBluffGameListTemp);
                setSelectedBluffGameList(optionalBluffGameListTemp.filter((game) => game.isFixed && game));
                // setSelectedBluffGameList(handleFixedSelectionForBluff([...optionalBluffGameListTemp]));
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };

    const handleFixedSelectionForBluff = (values) => {
        return values && values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
    };

    //   check when the maximum selection of bluff has reached if yes then disable selection
    const checkForDisabled = () => {
        var maxGameSelectionCountOtherThanFixed = 0;
        selectedBluffGameList.map((game) => {
            if (!game.isFixed || game.isFixed === "false") {
                maxGameSelectionCountOtherThanFixed += 1;
            }
        });
        if (maxGameSelectionCountOtherThanFixed >= maxBluffGameSelection) {
            return true;
        } else {
            return false;
        }
    };

    const onBluffGameListChange = (value, { action, removedValue }) => {
        switch (action) {
            case "remove-value":
            case "pop-value":
                if (removedValue.isFixed) {
                    return;
                }
                break;
            case "clear":
                value = bluffGameList.filter((v) => v.isFixed);
                break;
            default:
                break;
        }
        value = handleFixedSelectionForBluff(value);
        setSelectedBluffGameList(value);
    };

    // const betAudio = new Audio("gamePlayAssets/audio/bet.mp3");
    // const cardFlipAudio = new Audio("gamePlayAssets/audio/card_flip.mp3");
    // const potMixAudio = new Audio("gamePlayAssets/audio/pot_mix.wav");

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const PrettoSlider = styled(Slider)({
    // 	color: '#52af77',
    // 	height: 8,
    // 	'& .MuiSlider-track': {
    // 		border: 'none',
    // 	},
    // 	'& .MuiSlider-thumb': {
    // 		height: 24,
    // 		width: 24,
    // 		backgroundColor: '#fff',
    // 		border: '2px solid currentColor',
    // 		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
    // 			boxShadow: 'inherit',
    // 		},
    // 		'&:before': {
    // 			display: 'none',
    // 		},
    // 	},
    // 	'& .MuiSlider-valueLabel': {
    // 		lineHeight: 1.2,
    // 		fontSize: 12,
    // 		background: 'unset',
    // 		padding: 0,
    // 		width: 32,
    // 		height: 32,
    // 		borderRadius: '50% 50% 50% 0',
    // 		backgroundColor: '#52af77',
    // 		transformOrigin: 'bottom left',
    // 		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    // 		'&:before': { display: 'none' },
    // 		'&.MuiSlider-valueLabelOpen': {
    // 			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    // 		},
    // 		'& > *': {
    // 			transform: 'rotate(45deg)',
    // 		},
    // 	},
    // });
    const funSocketClient = () => {
        var tempSocketClient = new SocketClient({
            successCallBack: (action, responsePacket) => {
                setAction(action);
                if (action === "CanIBluff") {
                    setCanIBluff(responsePacket.canIBluff);
                }
                if (action === "MyDetail") {
                    setLoggedInPlayer(responsePacket.playerDetail);
                }
                if (responsePacket.tableId !== urlParamTableId) {
                    return;
                }
                if (action === "TableLog") {
                    return;
                }
                if (action === "UpdateTable") {
                    setGpTableModel(responsePacket.tableModel);
                } else if (responsePacket.tableModel !== undefined) {
                    setGpTableModel(responsePacket.tableModel);
                }
                if (action === "FlopOpen" || action === "TurnOpen" || action === "RiverOpen") {
                    // potMixAudio.play();
                    // cardFlipAudio.play();
                }
                // Result
                // CardDealing
                // FlopOpen
                // TurnTimer
                // TurnOpen
                // RiverOpen
                // GraceTimer
                // ReBuyIn
                // TableLog
            },
            failedCallBack: (action, responseJson) => {},
            onConnectCallBack: () => {
                tempSocketClient.getMyDetail();
                tempSocketClient.canIBluff();
                tempSocketClient.getTableDetail("NormalGame", urlParamTableId);
                tempSocketClient.joinTable("NormalGame", urlParamTableId);
            },
        });
        tempSocketClient.setClientIdAndPlayerAuth(urlParamClientId, urlParamUserName, urlParamSecret, urlParamUserType);
        tempSocketClient.initializeSocket();
        setSocketCaller(
            new SocketCaller({
                socketClient: tempSocketClient,
                gameType: "NormalGame",
                tableId: urlParamTableId,
            })
        );
    };

    const [tableLogoUrl, setTableLogoUrl] = useState("");

    const getTableLogo = () => {
        ApiCaller.callGetApi(
            url.MethodWebsiteDetail,
            {},
            (response) => {
                if (response.success) {
                    const res = response.responsePacket;
                    setTableLogoUrl(res.logoIcon);
                }
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };

    useEffect(() => {
        funSocketClient();
        getBluffGameList();
        getTableLogo();
    }, []);

    const handleResultOnBluff = () => {
        // in BluffGame (on message = "result" redirect to a new tab)
        if (action !== "Result" || gpTableModel?.gameConfig.gameType !== "BluffGame") {
            return;
        }
        getMyChairIndex();
        if (myChairIndex < 0) {
            return;
        }
        if (gpTableModel.bluffModel.gameId && gpTableModel.bluffModel.roundAmountDeducted) {
            if (gpTableModel.bluffModel.bluffAcceptedChairIds.includes(myChairIndex)) {
                openNewTabUsingURL(`https://goldclash.com/demo/bluff-active-screen/${gpTableModel?.chairs[myChairIndex].playerModel.thirdPartyPlayerId}/${gpTableModel.gameSessionId}`);
            }
        }
    };

    useEffect(() => {
        handleResultOnBluff();
    }, [action]);

    const openNewTabUsingURL = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    useEffect(() => {
        getMyChairIndex();
        if (gpTableModel?.chairs[myChairIndex]?.playerModel?.bluffGameList && !selectedBluffGameListWithRaise) {
            var tempSelectedGameList = [
                {
                    value: null,
                    label: "No Game",
                },
            ];
            tempSelectedGameList.push(...gpTableModel?.chairs[myChairIndex].playerModel.bluffGameList);
            setSelectedBluffGameListWithRaise(tempSelectedGameList);
            setSelectedGameFromBluffGame(tempSelectedGameList[0]);
        }
        if (myChairIndex > -1) {
            setJoined(true);
        } else {
            setJoined(false);
        }
    }, [gpTableModel]);

    const getLoader = () => {
        return (
            loaderVisible && (
                <div id="loader">
                    <div className="loder-box">
                        <div className="loader-box"></div>
                    </div>
                </div>
            )
        );
    };

    const getHiddenStaticResource = () => {
        return (
            <div style={{ display: "none" }}>
                <img src="gamePlayAssets/img/cards/CARD.png" alt="CARD.png" />
                {faceSuiteUtils.cardSuiteFace[0].map((suite, key) => {
                    return faceSuiteUtils.cardSuiteFace[1].map((face, key) => {
                        var imgSrc = "gamePlayAssets/img/cards/" + suite + "_" + face + ".png";
                        return <img key={suite + "_" + face} src={imgSrc} alt={suite + "_" + face} />;
                    });
                })}
                {chipsUtils.chipsSize[1].map((coin, key) => {
                    var imgSrc = "gamePlayAssets/img/chips/" + coin + ".png";
                    return <img key={coin} src={imgSrc} alt={imgSrc} />;
                })}
            </div>
        );
    };

    const getOtherCurrencySlider = () => {
        if (gpTableModel !== null && gpTableModel !== undefined) {
            if (gpTableModel.gameConfig.funReal === "Real") {
                return (
                    <>
                        <Box>
                            <Slider
                                valueLabelDisplay="auto"
                                value={currencySlider02}
                                defaultValue={chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.gameConfig.minimumBuyIn)}
                                min={chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.gameConfig.minimumBuyIn)}
                                max={chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.gameConfig.maximumBuyIn)}
                                step={gpTableModel.gameConfig.ChipsInOneDollar}
                                onChange={handleBuyInSliderChangeOther}
                            />
                        </Box>
                        <div className="d-flex justify-content-center">
                            <img className="purchaseChipsModal-chips-img" src="gamePlayAssets/img/poker-chip.png" alt="poker-chip.png" />
                            <p>{currencySlider02}</p>
                            &nbsp;&nbsp;
                            <p>&nbsp; TEST Coin</p>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <Box style={{ marginTop: "50px" }}>
                            <Slider
                                valueLabelDisplay="auto"
                                value={currencySlider02}
                                defaultValue={gpTableModel.gameConfig.minimumBuyIn / gpTableModel.gameConfig.ChipsInOneDollar}
                                min={gpTableModel?.gameConfig.minimumBuyIn / gpTableModel.gameConfig.ChipsInOneDollar}
                                max={gpTableModel?.gameConfig.maximumBuyIn / gpTableModel.gameConfig.ChipsInOneDollar}
                                step={1}
                                onChange={handleBuyInSliderChangeOther}
                            />
                        </Box>
                        <div className="d-flex justify-content-center">
                            <img className="purchaseChipsModal-chips-img" src="gamePlayAssets/img/poker-chip.png" alt="poker-chip.png" />
                            <p>{currencySlider02}</p>
                            &nbsp;&nbsp;
                            <p>&nbsp; GCC Coin</p>
                        </div>
                    </>
                );
            }
        }
        return <></>;
    };
    const takeStakeOnTakeSeatModel = () => {
        return (
            <div className="modal fade" id="takeStakeOnTakeSeatModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-none" style={{ background: "white", color: "black" }}>
                            Select Buy-In Amount
                        </div>
                        <div className="modal-body" style={{ margin: "30px 15px 0px 15px" }}>
                            <Box>
                                <Slider
                                    valueLabelDisplay="auto"
                                    value={currencySlider01}
                                    defaultValue={gpTableModel?.gameConfig.minimumBuyIn}
                                    min={gpTableModel?.gameConfig.minimumBuyIn}
                                    max={gpTableModel?.gameConfig.maximumBuyIn}
                                    step={gpTableModel?.gameConfig.funReal === "Real" ? 1 : gpTableModel?.gameConfig.ChipsInOneDollar}
                                    onChange={handleBuyInSliderChange}
                                />
                            </Box>
                            <div className="d-flex justify-content-center">
                                <img className="purchaseChipsModal-chips-img" src="gamePlayAssets/img/poker-chip.png" alt="poker-chip.png" />
                                <p id="selectedBuyInAmount">{currencySlider01}</p>
                                &nbsp;&nbsp;
                                <p>
                                    {gpTableModel?.gameConfig.funReal === "Real" ? "GCC" : "TEST"}
                                    &nbsp;Coin
                                </p>
                            </div>
                            {getOtherCurrencySlider()}
                            {gpTableModel?.gameConfig.gameType === "BluffGame" && (
                                <>
                                    <div className="justify-content-center">
                                        {/* must chosen Bluff game list selection */}
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={bluffGameList}
                                            className="text-dark"
                                            value={selectedBluffGameList}
                                            onChange={onBluffGameListChange}
                                            id="bluffGameList"
                                            isClearable={selectedBluffGameList && selectedBluffGameList.length > 0 && selectedBluffGameList.some((v) => !v.isFixed)}
                                            styles={bluffGameListStyles}
                                            isOptionDisabled={() => checkForDisabled()}
                                        />
                                    </div>
                                    {/* Bluff options */}
                                    <div className="d-flex justify-content-around mt-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="bluffTypeSection"
                                                id="bluffTypeSection1"
                                                value="option1"
                                                onChange={() => setSelectedBluffType("BLUFF_1")}
                                                checked={selectedBluffType === "BLUFF_1" ? true : false}
                                            />
                                            <label className="form-check-label" htmlFor="bluffTypeSection1">
                                                Bluff 1
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="bluffTypeSection"
                                                id="bluffTypeSection2"
                                                value="option2"
                                                onChange={() => setSelectedBluffType("BLUFF_2")}
                                                checked={selectedBluffType === "BLUFF_2" ? true : false}
                                            />
                                            <label className="form-check-label" htmlFor="bluffTypeSection2">
                                                Bluff 2
                                            </label>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="text-center mx-2 my-2">
                            <button className="btn btn-light btn-sm mr-3 cursor" data-dismiss="modal">
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm cursor" data-dismiss="modal" onClick={takeSeatAction}>
                                Take Seat
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const [selectedChairIndex, setSelectedChairIndex] = useState(0);
    const showTakeStakeOnTakeSeatModel = (chairIndex) => {
        setCurrencySlider01(gpTableModel?.gameConfig.minimumBuyIn);

        var otherValue =
            gpTableModel.gameConfig.funReal === "Real"
                ? gpTableModel?.gameConfig.minimumBuyIn * gpTableModel.gameConfig.ChipsInOneDollar
                : gpTableModel?.gameConfig.minimumBuyIn / gpTableModel.gameConfig.ChipsInOneDollar;
        setCurrencySlider02(otherValue);

        setSelectedChairIndex(chairIndex);
    };

    const handleBuyInSliderChange = (e) => {
        setCurrencySlider01(e.target.value);

        var otherValue = gpTableModel.gameConfig.funReal === "Real" ? e.target.value * gpTableModel.gameConfig.ChipsInOneDollar : e.target.value / gpTableModel.gameConfig.ChipsInOneDollar;
        setCurrencySlider02(otherValue);
    };
    const handleBuyInSliderChangeOther = (e) => {
        setCurrencySlider02(e.target.value);
        var otherValue = gpTableModel.gameConfig.funReal === "Real" ? e.target.value / gpTableModel.gameConfig.ChipsInOneDollar : e.target.value * gpTableModel.gameConfig.ChipsInOneDollar;
        setCurrencySlider01(otherValue);
    };
    const takeSeatAction = (e) => {
        var buyInAmount = currencySlider01; // Number($("#selectedBuyInAmount").html());
        if (gpTableModel?.gameConfig.gameType === "BluffGame") {
            // check if the selected games other than fixed selection is less than required selection
            var maxGameSelectionCountOtherThanFixed = 0;
            selectedBluffGameList.map((game) => {
                if (!game.isFixed || game.isFixed === "false") {
                    maxGameSelectionCountOtherThanFixed += 1;
                }
            });

            if (maxGameSelectionCountOtherThanFixed < minBluffGameSelection) {
                toast.error("Please select at least 2 games.", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
        }
        socketCaller.callTakeSeatOnBluff(selectedChairIndex, buyInAmount, 0, selectedBluffGameList, selectedBluffType);
    };

    // new code ends

    var myChairIndex = -1;
    const getMyChairIndex = () => {
        myChairIndex = -1;
        if (gpTableModel === null || gpTableModel === undefined) {
            return;
        }
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel != null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
                break;
            }
        }
    };
    const showHideActionButtons = () => {
        getMyChairIndex();
        if (myChairIndex > -1) {
            // I am on one Chair.
            if (gpTableModel.tableStatus === "Turn") {
                if (gpTableModel.chairs[myChairIndex].playerStatus === "Turn") {
                    // It's Not My Turn
                    return true;
                }
            }
        }
        return false;
    };
    const showHideCheckButtons = () => {
        getMyChairIndex();
        if (myChairIndex > -1) {
            // I am on one Chair.
            if (gpTableModel.tableStatus === "Turn") {
                if (gpTableModel.chairs[myChairIndex].playerStatus === "WaitingForTurn") {
                    // It's My Turn
                    return true;
                }
            }
        }
        return false;
    };

    const handleRaiseSliderChange = (event) => {
        updateRaiseValue(event.target.value);
    };
    const updateRaiseValue = (amount) => {
        var html =
            chipsUtils.getFormattedChipsTitle(amount) +
            (gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T") +
            "&nbsp;|&nbsp;" +
            chipsUtils.getFormattedChipsTitle(chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, amount)) +
            (gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G");
        $("#raiseAmount").html(html);
    };
    const handleRaiseSliderMinusButton = (event) => {
        var sliderAmount = $("#raiseSlider").val();
        sliderAmount = sliderAmount - gpTableModel.gameConfig.bigBlind;
        var minAmount = parseFloat($("#raiseSlider").attr("min"));
        if (sliderAmount < minAmount) {
            sliderAmount = minAmount;
        }
        $("#raiseSlider").val(sliderAmount);
        updateRaiseValue(sliderAmount);
    };
    const handleRaiseSliderPlusButton = (event) => {
        var sliderAmount = $("#raiseSlider").val();
        sliderAmount = parseFloat(sliderAmount) + gpTableModel.gameConfig.bigBlind;
        var maxAmount = parseFloat($("#raiseSlider").attr("max"));
        if (sliderAmount > maxAmount) {
            sliderAmount = maxAmount;
        }
        $("#raiseSlider").val(sliderAmount);
        updateRaiseValue(sliderAmount);
    };
    const handleRaiseAction = (event) => {
        var raiseAmount = parseFloat($("#raiseSlider").val());
        // betAudio.play();
        if (raiseAmount === gpTableModel.chairs[myChairIndex].playerModel.playBalance) {
            socketCaller?.callPlaceBet("AllIn", 0, selectedGameFromBluffGame && selectedGameFromBluffGame.value ? selectedGameFromBluffGame.value : null);
        } else {
            socketCaller?.callPlaceBet("Raise", parseFloat(raiseAmount), selectedGameFromBluffGame && selectedGameFromBluffGame.value ? selectedGameFromBluffGame.value : null);
        }
        setSelectedGameFromBluffGame(selectedBluffGameListWithRaise[0]);
    };
    const handleCheckButtonCheck = (event) => {
        var markCheck = event.target.checked;
        $(".checkButton").each(function () {
            $(this).prop("checked", false);
        });
        if (markCheck) {
            $("#" + event.target.id).prop("checked", true);
        }
    };
    const autoAct = () => {
        var foldChecked = $("#Fold").prop("checked");
        if (foldChecked !== undefined && foldChecked) {
            socketCaller?.callPlaceBet("Fold", 0);
            return true;
        }
        var checkChecked = $("#Check").prop("checked");
        if (checkChecked !== undefined && checkChecked) {
            socketCaller?.callPlaceBet("Check", 0);
            return true;
        }
        var callChecked = $("#Call").prop("checked");
        if (callChecked !== undefined && callChecked) {
            socketCaller?.callPlaceBet("Call", 0);
            return true;
        }
        var callAnyChecked = $("#CallAny").prop("checked");
        if (callAnyChecked !== undefined && callAnyChecked) {
            if (gpTableModel.chairs[myChairIndex].chairPotBalance === undefined || gpTableModel.minBetPrice === gpTableModel.chairs[myChairIndex].chairPotBalance) {
                socketCaller?.callPlaceBet("Check", 0);
            } else {
                socketCaller?.callPlaceBet("Call", 0);
            }
            return true;
        }
        return false;
    };

    const actionButtonView = () => {
        if (showHideActionButtons() && !autoAct()) {
            return (
                <div className="footer-div">
                    <div className="row align-items-center m-0">
                        <div className="col-md-12 col-sm-12 px-2">
                            <div className="range w-100 row align-items-center justify-content-center " ms-controller="range-primary" style={{ margin: 0 }}>
                                {gpTableModel.bluffModel.bluffStartedByChairId === null ||
                                gpTableModel.bluffModel.bluffStartedByChairId === undefined ||
                                (gpTableModel.bluffModel.bluffStartedByChairId !== null && gpTableModel.bluffModel.roundFinished) ? (
                                    <>
                                        {
                                            /* Fold Button */
                                            gpTableModel.chairs[myChairIndex].foldButton ? (
                                                <div>
                                                    <button className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Fold", 0)}>
                                                        Fold
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                        {
                                            /* Check Button */
                                            gpTableModel.chairs[myChairIndex].checkButton ? (
                                                <div>
                                                    <button className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Check", 0)}>
                                                        Check
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                        {
                                            /* Call Button */
                                            gpTableModel.chairs[myChairIndex].callButton ? (
                                                <div>
                                                    <button
                                                        className="btn bg-gradient-call text-white mr-2"
                                                        onClick={() => {
                                                            // betAudio.play();
                                                            socketCaller?.callPlaceBet("Call", 0);
                                                        }}
                                                    >
                                                        CALL{" "}
                                                        <b className="" style={{ display: "block" }}>
                                                            {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.betPrice !== null ? gpTableModel.betPrice : 0)}{" "}
                                                            {gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T"}
                                                            &nbsp;|&nbsp;
                                                            {chipsUtils.getFormattedChipsTitle(
                                                                gpTableModel !== null && gpTableModel.betPrice !== null
                                                                    ? chipsUtils.getOtherCurrencyAmount(
                                                                          gpTableModel.gameConfig.funReal,
                                                                          gpTableModel.gameConfig.ChipsInOneDollar,
                                                                          gpTableModel.betPrice
                                                                      )
                                                                    : 0
                                                            )}{" "}
                                                            {gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G"}
                                                        </b>
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                        {
                                            /* Raise Button */
                                            gpTableModel.chairs[myChairIndex].raiseButton ? (
                                                <div className="custam-progress-bar progress-desktop-div">
                                                    <div className="slider-div">
                                                        <div className="mr-2" onClick={handleRaiseSliderMinusButton}>
                                                            <button className="progres-caunting-btn">-</button>
                                                        </div>
                                                        <div className="mr-2" style={{ width: "100%", overflow: "hidden" }}>
                                                            <div className="range-container">
                                                                <div className="range-track progress">
                                                                    <div className="range-track-bar progress-bar active progress-bar-primary" ms-css-width='value + "%"' />
                                                                </div>
                                                                <input
                                                                    className="range-control w-100"
                                                                    type="range"
                                                                    id="raiseSlider"
                                                                    onChange={handleRaiseSliderChange}
                                                                    min={gpTableModel.betPrice * 2}
                                                                    max={gpTableModel.chairs[myChairIndex].playerModel.playBalance}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mr-2" onClick={handleRaiseSliderPlusButton}>
                                                            <button className="progres-caunting-btn"> + </button>
                                                        </div>
                                                    </div>
                                                    {gpTableModel?.gameConfig.gameType === "BluffGame" &&
                                                        (gpTableModel.bluffModel.bluffStartedByChairId === null || gpTableModel.bluffModel.bluffStartedByChairId === undefined) &&
                                                        canIBluff &&
                                                        !gpTableModel.bluffModel.bluffPostedByChairIds.includes(myChairIndex) && (
                                                            <Select
                                                                options={selectedBluffGameListWithRaise}
                                                                className="text-dark custom_select mr-2"
                                                                value={selectedGameFromBluffGame}
                                                                menuPlacement="top"
                                                                onChange={(e) => setSelectedGameFromBluffGame(e)}
                                                                id="selectGameFromBluffGameList"
                                                            />
                                                        )}
                                                    <div>
                                                        <button className="btn bg-gradient-raise text-white mr-2" onClick={handleRaiseAction}>
                                                            RAISE{" "}
                                                            <b className="" style={{ display: "block" }} id="raiseAmount">
                                                                {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.betPrice !== null ? gpTableModel.betPrice * 2 : 0)}{" "}
                                                                {gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T"}
                                                                &nbsp;|&nbsp;
                                                                {chipsUtils.getFormattedChipsTitle(
                                                                    gpTableModel !== null && gpTableModel.betPrice !== null
                                                                        ? chipsUtils.getOtherCurrencyAmount(
                                                                              gpTableModel.gameConfig.funReal,
                                                                              gpTableModel.gameConfig.ChipsInOneDollar,
                                                                              gpTableModel.betPrice * 2
                                                                          )
                                                                        : 0
                                                                )}{" "}
                                                                {gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G"}
                                                            </b>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                        {
                                            /* All In Button */
                                            gpTableModel.chairs[myChairIndex].allInButton ? (
                                                <div>
                                                    <button className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("AllIn", 0)}>
                                                        All In
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                    </>
                                ) : (
                                    gpTableModel?.gameConfig.gameType === "BluffGame" && (
                                        <>
                                            <span>{`${gpTableModel?.chairs[gpTableModel.bluffModel?.bluffStartedByChairId]?.playerModel?.visibleName} has raised ${
                                                gpTableModel.bluffModel?.bluffAmount
                                            } amount on ${gpTableModel.bluffModel?.gameTitle} game with ${gpTableModel.bluffModel?.bluffType}`}</span>
                                            {showHideActionButtons() && getBluffActionButtons()}
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (showHideCheckButtons()) {
            return (
                <div className="footer-div">
                    <div className="row align-items-center m-0">
                        {/* Check Boxes */}
                        <div className="col-md-12 px-2">
                            <div className="chekboxdiv">
                                {gpTableModel.bluffModel.bluffStartedByChairId === null ||
                                gpTableModel.bluffModel.bluffStartedByChairId === undefined ||
                                (gpTableModel.bluffModel.bluffStartedByChairId !== null && gpTableModel.bluffModel.roundFinished) ? (
                                    <>
                                        {
                                            /* Fold Check Button */
                                            <div className="custom-control custom-checkbox bg-gradient-ok">
                                                <input type="checkbox" id="Fold" name="Fold" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                                <label className="custom-control-label" htmlFor="Fold">
                                                    Fold
                                                </label>
                                            </div>
                                        }
                                        {gpTableModel.chairs[myChairIndex].chairPotBalance === undefined || gpTableModel.minBetPrice === gpTableModel.chairs[myChairIndex].chairPotBalance ? (
                                            <div className="custom-control custom-checkbox bg-gradient-ok">
                                                <input type="checkbox" id="Check" name="Check" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                                <label className="custom-control-label" htmlFor="Check">
                                                    Check
                                                </label>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {gpTableModel.minBetPrice <= gpTableModel.gameConfig.bigBlind ? (
                                            <div className="custom-control custom-checkbox bg-gradient-ok">
                                                <input type="checkbox" id="Call" name="Call" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                                <label className="custom-control-label" htmlFor="Call">
                                                    Call
                                                </label>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {
                                            /* gpTableModel.minBetPrice <= gpTableModel.gameConfig.bigBlind ? */
                                            <div className="custom-control custom-checkbox bg-gradient-ok">
                                                <input type="checkbox" id="CallAny" name="CallAny" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                                <label className="custom-control-label" htmlFor="CallAny">
                                                    Call Any
                                                </label>
                                            </div>
                                        }
                                    </>
                                ) : (
                                    gpTableModel?.gameConfig.gameType === "BluffGame" && (
                                        <>
                                            <span>{`${gpTableModel?.chairs[gpTableModel.bluffModel?.bluffStartedByChairId]?.playerModel?.visibleName} has raised ${
                                                gpTableModel.bluffModel?.bluffAmount
                                            } amount on ${gpTableModel.bluffModel?.gameTitle} game with ${gpTableModel.bluffModel?.bluffType}`}</span>
                                            {showHideActionButtons() && getBluffActionButtons()}
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };
    const getBluffActionButtons = () => {
        getMyChairIndex();
        if (myChairIndex < 0) {
            return <></>;
        }

        if (gpTableModel.bluffModel.bluffStartedByChairId === null || gpTableModel.bluffModel.bluffStartedByChairId === undefined) {
            return;
        } else {
            let alreadyActed = gpTableModel.bluffModel?.bluffAcceptedChairIds?.some((chairIdx) => chairIdx === myChairIndex);
            if (!alreadyActed) {
                alreadyActed = gpTableModel.bluffModel?.bluffRejectedChairIds?.some((chairIdx) => chairIdx === myChairIndex);
            }
            if (!alreadyActed) {
                return getBluffAcceptRejectRemainingButtons(myChairIndex);
            }
        }
        return <></>;
    };

    const getBluffAcceptRejectRemainingButtons = (myChairIndex) => {
        if (gpTableModel.chairs[myChairIndex].callButton) {
            return (
                <>
                    <button className="btn bg-gradient-call text-white ml-3" onClick={() => socketCaller?.callPlaceBet("Call", 0)}>
                        Accept
                    </button>
                    <button className="btn bg-gradient-call text-white ml-2" onClick={() => socketCaller?.callPlaceBet("Fold", 0)}>
                        Reject
                    </button>
                </>
            );
        } else if (gpTableModel.chairs[myChairIndex].allInButton) {
            return (
                <>
                    <button className="btn bg-gradient-ok text-white ml-3" onClick={() => socketCaller?.callPlaceBet("AllIn", 0)}>
                        Accept
                    </button>
                    <button className="btn bg-gradient-call text-white ml-2" onClick={() => socketCaller?.callPlaceBet("Fold", 0)}>
                        Reject
                    </button>
                </>
            );
        }
    };

    return (
        <>
            <div id="wrapper" theme="felt" cardback="orange" layout="row" className="layout-row main">
                {getLoader()}
                {getHiddenStaticResource()}
                <div id="page-content-wrapper" className="main-container layout-column">
                    {/* Header Start */}
                    <HeaderBar loggedInPlayer={loggedInPlayer} socketCaller={socketCaller} tableId={urlParamTableId} gpTableModel={gpTableModel} />
                    {/* Header End */}
                    <div className="main-view flex">
                        <div className="layout-row container1 p-b-100">
                            <div className="table-container layout-align-center-center layout-column flex">
                                <div className="game-table circular-seats">
                                    <div className="innerBorder circular-bets _md md-dance-theme w-100 h-100 ">
                                        <Table
                                            loggedInPlayer={loggedInPlayer}
                                            socketCaller={socketCaller}
                                            tableId={urlParamTableId}
                                            gpTableModel={gpTableModel}
                                            showTakeStakeOnTakeSeatModel={showTakeStakeOnTakeSeatModel}
                                            tableLogoUrl={tableLogoUrl}
                                        />
                                        {takeStakeOnTakeSeatModel()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Buttons Start */}
                    {actionButtonView()}
                    {/* Footer Buttons End */}
                </div>
            </div>
        </>
    );
};

export default GamePlay;
