import './App.css';
import MainContentComponent from './Components/Common/MainContentComponent';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
    return (
        <>
            <Router>
                <MainContentComponent />
            </Router>
        </>
    );
}

export default App;
