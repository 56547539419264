import React from 'react'

const LearnComponent = () => {
    return (
        <>
            <div>
                <section className="custom-main-content pt-85">
                    <div className="container">
                        <div className="text-center pt-85">
                            <h2 className="text-white">Learn how to play poker</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut
                                <br /> labore et dolore magna aliqua. Bibendum est ultricies integer
                                quis.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="container">
                        <div className="contant-box px-4 py-5">
                            <h4 className="text-white mt-3">
                                01. How to transfer money wallet to bank
                            </h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                                ut placerat orci nulla. Tincidunt ornare Dictum fusce ut placerat orci
                            </p>
                            <h4 className="text-white">02. How to transfer money wallet to bank</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                                ut placerat orci nulla. Tincidunt ornare Dictum fusce ut placerat orci
                            </p>
                            <h4 className="text-white">03. How to transfer money wallet to bank</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                                ut placerat orci nulla. Tincidunt ornare Dictum fusce ut placerat orci
                            </p>
                            <h4 className="text-white">04. How to transfer money wallet to bank</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                                ut placerat orci nulla. Tincidunt ornare Dictum fusce ut placerat orci
                            </p>
                        </div>
                    </div>
                </section>
                <section className="pt-5">
                    <div className=" container">
                        <div className="text-center">
                            <h2 className="text-white">Texas Hold’em - Hand Ranking</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut
                                <br /> labore et dolore magna aliqua. Bibendum est ultricies integer
                                quis.
                            </p>
                        </div>
                        <div className="row mt-4">
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                            <div className=" col-lg-4 col-md-4 col-sm-6 my-2">
                                <img alt="card-img.png" src="assets/img/card-img.png" width="100%" />
                                <h6 className="text-white mt-3">Royal Flush</h6>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default LearnComponent