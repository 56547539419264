import React, { useEffect } from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";

const LoginComponent = (props) => {
    const [requestData, setRequestData] = useState({
        emailOrMobile: "",
        password: "",
        rememberMe: false,
    });

    useEffect(() => {
        initPageJs();
    }, []);

    const initPageJs = () => {
        $(".toggle-password").off("click");
        $(".toggle-password").on("click", function (e) {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    const handleLogin = () => {
        if (requestData.emailOrMobile.trim() === "") {
            toast("Email/Mobile is required!!", {
                icon: "🙁",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        } else if (requestData.password.trim() === "") {
            toast("Password is required!!", {
                icon: "🙁",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        } else {
            var requestPacket = {
                username: requestData.emailOrMobile,
                password: requestData.password,
            };
            ApiCaller.callPostApi(
                url.MethodLogin,
                requestPacket,
                {},
                (response) => {
                    if (response.success) {
                        var loginData = response.responsePacket;
                        const cookies = new Cookies();
                        let d = new Date();
                        d.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                        cookies.set("pokerLoginData", loginData, { path: "/", expires: d });
                        var authToken = btoa(loginData.username + ":" + loginData.uniqueKey);
                        const cookies1 = new Cookies();
                        let d1 = new Date();
                        d1.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                        cookies1.set("pokerAuth", authToken, { path: "/", expires: d1 });
                        //debugger;
                        toast("Login Successfully", {
                            icon: "👏",
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                        window.location.href = "/profile";
                    } else {
                        toast(response.message, {
                            icon: "🙁",
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                    }
                },
                (error) => {
                    console.log("FAILED : ", error);
                }
            );
        }
    };

    return (
        <>
            <section className="mt-85">
                <div className="container">
                    <div className="row">
                        <div className=" col-lg-5 col-md-5 col-sm-5 mx-auto mt-5">
                            <h2 className="text-white text-center">
                                Welcome to <br />
                                Gold Clash Poker
                            </h2>
                            <div className="form-div mt-5">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter email or Mobile number"
                                        onChange={(e) =>
                                            setRequestData({
                                                ...requestData,
                                                emailOrMobile: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        id="password-field"
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        placeholder="Password"
                                        onChange={(e) =>
                                            setRequestData({
                                                ...requestData,
                                                password: e.target.value,
                                            })
                                        }
                                        onKeyPress={handleKeyPress}
                                    />
                                    <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password cursor"></span>
                                </div>
                                <div className="form-check form-group mt-4">
                                    <input
                                        className="styled-checkbox"
                                        id="styled-checkbox-1"
                                        type="checkbox"
                                        defaultValue="value1"
                                        onChange={(e) =>
                                            setRequestData({
                                                ...requestData,
                                                rememberMe: e.target.checked,
                                            })
                                        }
                                    />
                                    <label htmlFor="styled-checkbox-1">Remember me</label>
                                </div>
                                <div className="form-group mt-4">
                                    <Link to="" className="btn custom-btn text-white d-block" onClick={handleLogin}>
                                        Login Account
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster />
        </>
    );
};

export default LoginComponent;
