import { Component } from 'react';
import $ from 'jquery';
import * as constant from "../../../Const";
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

class SocketClient extends Component {
	constructor(props) {
		super(props);
		// this.clientId = props.clientId,
		// this.playerAuth = props.playerAuth,
		this.successCallBack = props.successCallBack;
		this.failedCallBack = props.failedCallBack;
		this.onConnectCallBack = props.onConnectCallBack;
		this.isConnectionOpen = false;
	}

	setClientIdAndPlayerAuth = (clientId, userName, secret, userType = "P") => {
		this.clientId = clientId;
		this.userName = userName;
		this.secret = secret;
		if(userType === undefined || userType === null  || userType === "") {
			this.userType = "P";
		} else {
			this.userType = userType;
		}
		this.playerAuth = "Basic " + btoa(this.userName + ":" + this.secret);
	}

	getSocketUrl = (method) => {
		var socketUrl = constant.WSS + "://" + constant.HOST + ":" + constant.PORT + "/" + constant.SERVICE + method;
		// var socketUrl = constant.WSS'ws://localhost:9005/gamePlay';
		return socketUrl;
	}

	initializeSocket = () => {
		let _that = this;
		_that.showLoader();
		_that.socket = new WebSocket(_that.getSocketUrl("gamePlay"));
		_that.socket.binaryType = "arraybuffer";
		_that.socket.onopen = (event) => {
			_that.isConnectionOpen = true;
			_that.hideLoader();
			if (_that.timerId != null) {
				clearInterval(_that.timerId);
				_that.timerId = null;
			}
			_that.socket.onclose = function (event) {
				_that.isConnectionOpen = false;
				_that.showLoader();
				_that.timerId = setInterval(function () {
					_that.initializeSocket();
				}, 5000);
			};
			_that.onConnectCallBack();
		};
		_that.socket.onmessage = function (event) {
			_that.hideLoader();
			const responseJson = JSON.parse(event.data);
			if (responseJson.errorCode === 0) {
				_that.successCallBack(responseJson.action, responseJson.responsePacket);
			} else {
				toast.error(responseJson.message);
				console.error("================= ERROR =================");
				console.error(responseJson);
				console.error("================= ERROR =================");
				if (responseJson.message === "Unauthorised Access") {
					// Logout USER.
					// showFullDialog("Oops...", responseJson.message, null, function () { }, null, null);
					const cookies = new Cookies();
					cookies.remove("pokerLoginData");
					cookies.remove("pokerAuth");
					window.location.href = "/login";
				} else {
					// showFullDialog("Oops...", responseJson.message, null, function () { }, null, null);
				}
				_that.failedCallBack(responseJson.action, responseJson);
			}
		};
		return () => _that.socket.close();
	}
	sendRequest = (jsonObj) => {
		if(this.isConnectionOpen){
			this.showLoader();
			jsonObj["authorization"] = this.playerAuth;
			jsonObj["ut"] = this.userType;
			jsonObj["clientId"] = this.clientId;
			this.socket.send(JSON.stringify(jsonObj));
		}
		// this.socket.send(JSON.stringify(jsonObj));
	}
	closeConnection = () => {
		this.socket.close();
	}
	hideLoader = () => {
		$("#loader").hide();
	}
	showLoader = () => {
		$("#loader").show();
	}


	getMyDetail = () => {
        var jsonObj = {};
		jsonObj["action"] = "MyDetail";
        this.sendRequest(jsonObj);
    }
	canIBluff = () => {
        var jsonObj = {};
		jsonObj["action"] = "CanIBluff";
        this.sendRequest(jsonObj);
    }
	getTableDetail = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "TableDetail";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		console.log(jsonObj);
        this.sendRequest(jsonObj);
    }
    joinTable = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "JoinTable";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
        this.sendRequest(jsonObj);
    }
    unJoinTable = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "UnJoinTable";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
        this.sendRequest(jsonObj);
    }
    takeSeat = (gameType, tableId, chairId, buyInAmount, rhsBuyInAmount) => {
        var jsonObj = {};
		jsonObj["action"] = "TakeSeat";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		jsonObj["chairId"] = chairId;
		jsonObj["buyInAmount"] = buyInAmount;
		jsonObj["rhsBuyInAmount"] = rhsBuyInAmount;
        this.sendRequest(jsonObj);
    }
    takeSeatOnBluff = (gameType, tableId, chairId, buyInAmount, rhsBuyInAmount, bluffGameList, bluffType) => {
        var jsonObj = {};
		jsonObj["action"] = "TakeSeat";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		jsonObj["chairId"] = chairId;
		jsonObj["buyInAmount"] = buyInAmount;
		jsonObj["rhsBuyInAmount"] = rhsBuyInAmount;
		jsonObj["bluffGameList"] = bluffGameList;
		jsonObj["bluffType"] = bluffType;
        this.sendRequest(jsonObj);
    }
    leaveSeat = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "LeaveSeat";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
        this.sendRequest(jsonObj);
    }
    sitOut = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "SitOut";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
        this.sendRequest(jsonObj);
    }
    sitBack = (gameType, tableId) => {
        var jsonObj = {};
		jsonObj["action"] = "SitBack";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
        this.sendRequest(jsonObj);
    }
    changeBigBlindStatus = (gameType, tableId, bigBlindPostStatus) => {
        var jsonObj = {};
		jsonObj["action"] = "ChangeBigBlindStatus";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		jsonObj["bigBlindPostStatus"] = bigBlindPostStatus;
        this.sendRequest(jsonObj);
    }
    placeBet = (gameType, tableId, chalType, chalAmount, bluffGameId) => {
        var jsonObj = {};
		jsonObj["action"] = "PlaceBet";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		jsonObj["chalType"] = chalType;
		jsonObj["chalAmount"] = chalAmount;
		jsonObj["bluffGameId"] = bluffGameId;
        this.sendRequest(jsonObj);
    }
    reBuyIn = (gameType, tableId, buyInAmount) => {
        var jsonObj = {};
		jsonObj["action"] = "ReBuyIn";
		jsonObj["gameType"] = gameType;
		jsonObj["tableId"] = tableId;
		jsonObj["buyInAmount"] = buyInAmount;
        this.sendRequest(jsonObj);
    }
}

export default SocketClient;