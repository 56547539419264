import { Component } from 'react';

class UrlUtils extends Component {
    // constructor(props) {
	// 	super(props);
	// }
	
	getUrlParameter = (sParam) => {
		var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return "";
	}
}

export default UrlUtils;