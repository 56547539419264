import React from "react";
import { useState, useEffect } from "react";
import Card from "../GamePlay/Card";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";
import $ from "jquery";

export const Chair = (props) => {
    const [indexClass, setIndexClass] = useState(null);
    const [chairIndex, setChairIndex] = useState(null);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [rhsGpTableModel, setRhsGpTableModel] = useState(null);
    const [gpChairModel, setGpChairModel] = useState(null);
    const [rhsGpChairModel, setRhsGpChairModel] = useState(null);

    const [showSmallBlindButton, setShowSmallBlindButton] = useState(false);
    const [showBigBlindButton, setShowBigBlindButton] = useState(false);
    const [showYouButton, setShowYouButton] = useState(false);

    const [loggedInPlayer, setLoggedInPlayer] = useState(null);

    const chipsUtils = new ChipsUtils();

    useEffect(() => {
        setIndexClass(props.indexClass);
        setChairIndex(props.chairIndex);
        setGpTableModel(props.gpTableModel);
        setRhsGpTableModel(props.rhsGpTableModel);
        setGpChairModel(props.gpChairModel);
        setRhsGpChairModel(props.rhsGpChairModel);
        setLoggedInPlayer(props.loggedInPlayer);

        let progressElementId = props.gpChairModel.index + "_" + "progressBar";
        if (props.gpTableModel.tableStatus === "Turn") {
            if (props.gpChairModel.playerStatus === "Turn") {
                console.log(
                    props.gpChairModel.index,
                    props.gpTableModel.tableStatus,
                    props.gpChairModel.playerStatus,
                    props.gpTableModel.tableTurnTimer.countDown,
                    props.gpTableModel.gameConfig.turnTime + props.gpTableModel.gameConfig.graceTime
                );

                var timeLeft = props.gpTableModel.tableTurnTimer.countDown;
                var elementWidth = $("#" + progressElementId).width();
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: elementWidth }, 1, "linear");
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, timeLeft * 1000, "linear");
            } else if (props.gpChairModel.playerStatus === "WaitingForTurn") {
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, 1, "linear");
            }
        } else if (props.rhsGpTableModel?.tableStatus === "Turn") {
            if (props.rhsGpChairModel.playerStatus === "Turn") {
                console.log(
                    props.rhsGpChairModel.index,
                    props.rhsGpTableModel.tableStatus,
                    props.rhsGpChairModel.playerStatus,
                    props.rhsGpTableModel.tableTurnTimer.countDown,
                    props.rhsGpTableModel.gameConfig.turnTime + props.rhsGpTableModel.gameConfig.graceTime
                );

                var timeLeft = props.rhsGpTableModel.tableTurnTimer.countDown;
                var elementWidth = $("#" + progressElementId).width();
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: elementWidth }, 1, "linear");
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, timeLeft * 1000, "linear");
            } else if (props.rhsGpChairModel.playerStatus === "WaitingForTurn") {
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, 1, "linear");
            }
        } else {
            $("#" + progressElementId)
                .find("div.bar")
                .stop();
            $("#" + progressElementId)
                .find("div.bar")
                .animate({ width: 0 }, 1, "linear");
        }
    }, [props.indexClass, props.chairIndex, props.gpTableModel, props.rhsGpTableModel, props.gpChairModel, props.rhsGpChairModel, props.socketCaller, props.loggedInPlayer]);

    const getMyChairIndex = () => {
        let myChairIndex = -1;
        if (gpTableModel === null || gpTableModel === undefined) {
            return -1;
        }
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel != null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
                break;
            }
        }
        return myChairIndex;
    };

    const drawTakeSeatButton = () => {
        let button = "";
        if (parseInt(loggedInPlayer.playerId) > 0) {
            button = (
                <button
                    type="button"
                    onClick={() => props.showTakeStakeOnTakeSeatModel(chairIndex)}
                    data-toggle="modal"
                    data-target="#takeStakeOnTakeSeatModel"
                    className="md-fab seat-button md-button md-dance-theme md-ink-ripple layout-align-center-center layout-row margin-0-auto"
                >
                    <span className="">Sit</span>
                    <div className="md-ripple-container"></div>
                </button>
            );
        } else {
            button = (
                <button type="button" className="md-fab seat-button md-button md-dance-theme md-ink-ripple layout-align-center-center layout-row margin-0-auto">
                    <span className="">E</span>
                    <div className="md-ripple-container"></div>
                </button>
            );
        }
        return (
            <>
                <div className="s-button">
                    {button}
                    {gpChairModel.index === gpTableModel.dealerButtonChairId && <button className="tooltip md-fab md-mini d-button">D</button>}
                </div>
            </>
        );
    };

    const drawTossCardsView = () => {
        var showTossCard = false;
        var winnerLooserClass = "";
        if (gpTableModel.tableStatus === "Toss") {
            if (gpChairModel && gpChairModel.tossCard) {
                showTossCard = true;
                winnerLooserClass = gpChairModel.isTossWinner ? "toss-winner " : "toss-looser ";
            }
        }
        return (
            showTossCard && (
                <div className="hand">
                    <div className="d-flex card-hide">
                        <Card key={chairIndex + "_toss_card_"} cardPosition={winnerLooserClass + "chair-toss"} gpTableModel={gpTableModel} gpCardModel={gpChairModel.tossCard} />
                    </div>
                </div>
            )
        );
    };

    const drawMyCardsView = () => {
        // if myCards has cards, show Open Cards, else show fold cards.
        // if table status is Turn then show Fold Cards.
        var inHandCardCount = 2;
        if (gpTableModel.gameConfig.gameTableType === "Omaha") {
            inHandCardCount = 4;
        }
        var showFoldCards = false;
        if (gpTableModel.tableStatus === "CardDealing" || gpTableModel.tableStatus === "Turn" || gpTableModel.tableStatus === "Result") {
            if (
                gpChairModel &&
                !(
                    gpChairModel.playerStatus === "None" ||
                    gpChairModel.playerStatus === "WaitingForGame" ||
                    gpChairModel.playerStatus === "WaitingForBigBlind" ||
                    gpChairModel.playerStatus === "AutoPostBigBlind" ||
                    gpChairModel.playerStatus === "Fold" ||
                    gpChairModel.playerStatus === "Bust"
                )
            ) {
                showFoldCards = true;
            }
        }
        return (
            <div className="hand leftHand">
                <div className="d-flex card-hide">
                    {gpChairModel.myCards.length > 0 &&
                        gpChairModel.playerStatus !== "Fold" &&
                        gpChairModel.myCards.map((card, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={gpTableModel} gpCardModel={card} />;
                        })}
                    {/* TODO > Table Status Check Pending */}
                    {showFoldCards &&
                        gpChairModel.myCards.length === 0 &&
                        [...Array(inHandCardCount)].map((number, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={gpTableModel} gpCardModel={null} />;
                        })}
                </div>
            </div>
        );
    };

    const drawMyRhsCardsView = () => {
        if (rhsGpTableModel === null) {
            return <></>;
        }
        // if myCards has cards, show Open Cards, else show fold cards.
        // if table status is Turn then show Fold Cards.
        var inHandCardCount = 2;
        if (rhsGpTableModel.gameConfig.gameTableType === "Omaha") {
            inHandCardCount = 4;
        }
        var showFoldCards = false;
        if (rhsGpTableModel.tableStatus === "CardDealing" || rhsGpTableModel.tableStatus === "Turn" || rhsGpTableModel.tableStatus === "Result") {
            if (
                rhsGpChairModel &&
                !(
                    rhsGpChairModel.playerStatus === "None" ||
                    rhsGpChairModel.playerStatus === "WaitingForGame" ||
                    rhsGpChairModel.playerStatus === "WaitingForBigBlind" ||
                    rhsGpChairModel.playerStatus === "AutoPostBigBlind" ||
                    rhsGpChairModel.playerStatus === "Fold" ||
                    rhsGpChairModel.playerStatus === "Bust"
                )
            ) {
                showFoldCards = true;
            }
        }
        return (
            <div className="hand rightHand">
                <div className="d-flex card-hide">
                    {rhsGpChairModel.myCards.length > 0 &&
                        rhsGpChairModel.playerStatus !== "Fold" &&
                        rhsGpChairModel.myCards.map((card, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={rhsGpTableModel} gpCardModel={card} />;
                        })}
                    {/* TODO > Table Status Check Pending */}
                    {showFoldCards &&
                        rhsGpChairModel.myCards.length === 0 &&
                        [...Array(inHandCardCount)].map((number, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={rhsGpTableModel} gpCardModel={null} />;
                        })}
                </div>
            </div>
        );
    };

    const drawChairPotAmountAndStake = () => {
        if (gpChairModel.chairPotBalance != null && gpChairModel.chairPotBalance > 0) {
            return (
                <div
                    className={"chips" + indexClass}
                    style={{
                        animationDuration: "1s",
                        animationFillMode: "forwards",
                    }}
                >
                    <div className="custom-chips text-center">
                        <div className="d-flex justify-content-center">{chipsUtils.getChipsStake(gpChairModel.chairPotBalance)}</div>
                        <div className="mt-2 text-white">
                            <span>{chipsUtils.getFormattedChipsTitle(gpChairModel.chairPotBalance)}</span>
                        </div>
                    </div>
                </div>
            );
        } else if (rhsGpChairModel.chairPotBalance != null && rhsGpChairModel.chairPotBalance > 0) {
            return (
                <div
                    className={"chips" + indexClass}
                    style={{
                        animationDuration: "1s",
                        animationFillMode: "forwards",
                    }}
                >
                    <div className="custom-chips text-center">
                        <div className="d-flex justify-content-center">{chipsUtils.getChipsStake(rhsGpChairModel.chairPotBalance)}</div>
                        <div className="mt-2 text-white">
                            <span>{chipsUtils.getFormattedChipsTitle(rhsGpChairModel.chairPotBalance)}</span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const playerLhsHandResult = () => {
        return (
            gpTableModel &&
            gpTableModel.tableStatus === "Result" &&
            gpChairModel &&
            !(
                gpChairModel.playerStatus === "None" ||
                gpChairModel.playerStatus === "WaitingForBigBlind" ||
                gpChairModel.playerStatus === "AutoPostBigBlind" ||
                gpChairModel.playerStatus === "Fold" ||
                gpChairModel.playerStatus === "Bust"
            ) &&
            gpChairModel.pokerHandSuggestion && (
                <a href="#" className="name md-title md-button md-dance-theme lhsPlayerHandResult">
                    {gpChairModel.pokerHandSuggestion}
                </a>
            )
        );
    };

    const playerRhsHandResult = () => {
        return (
            rhsGpTableModel &&
            rhsGpTableModel.tableStatus === "Result" &&
            rhsGpChairModel &&
            !(
                rhsGpChairModel.playerStatus === "None" ||
                rhsGpChairModel.playerStatus === "WaitingForBigBlind" ||
                rhsGpChairModel.playerStatus === "AutoPostBigBlind" ||
                rhsGpChairModel.playerStatus === "Fold" ||
                rhsGpChairModel.playerStatus === "Bust"
            ) &&
            rhsGpChairModel.pokerHandSuggestion && (
                <a href="#" className="name md-title md-button md-dance-theme rhsPlayerHandResult">
                    {rhsGpChairModel.pokerHandSuggestion}
                </a>
            )
        );
    };

    const playerHandResult = () => {
        if (gpTableModel && gpTableModel.tableStatus === "Result" && rhsGpTableModel && rhsGpTableModel.tableStatus === "Result") {
            return (
                <>
                    <div className="player-hand-result">
                        {playerLhsHandResult()}
                        {playerRhsHandResult()}
                    </div>
                </>
            );
        } else {
            return <></>;
        }
    };

    const showPlayerStatusButton = () => {
        var showLhsButton = false;
        var lhsButtonClass = "";
        var lhsButtonText = "";
        if (gpChairModel && gpChairModel.playerModel) {
            lhsButtonText = gpChairModel.chalStatus;
            if (gpChairModel.playerStatus === "Bust") {
                showLhsButton = true;
                lhsButtonClass = "sitOut-bg";
                lhsButtonText = "Burst";
            } else if (gpChairModel.playerStatus === "SitOut") {
                showLhsButton = true;
                lhsButtonClass = "sitOut-bg";
                lhsButtonText = "Sit Out";
            } else if (gpChairModel.playerStatus === "Fold") {
                showLhsButton = true;
                lhsButtonClass = "fold-bg";
                lhsButtonText = "Fold";
            } else if (gpChairModel.chalStatus === "Check") {
                showLhsButton = true;
                lhsButtonClass = "check-bg";
            } else if (gpChairModel.chalStatus === "Call") {
                showLhsButton = true;
                lhsButtonClass = "call-bg";
            } else if (gpChairModel.chalStatus === "Raise") {
                showLhsButton = true;
                lhsButtonClass = "raise-bg";
            } else if (gpChairModel.chalStatus === "AllIn") {
                showLhsButton = true;
                lhsButtonClass = "allin-bg";
            } else if (gpChairModel.chalStatus === "Fold") {
                showLhsButton = true;
                lhsButtonClass = "fold-bg";
            }
        }

        var showRhsButton = false;
        var rhsButtonClass = "";
        var rhsButtonText = "";
        if (rhsGpChairModel && rhsGpChairModel.playerModel) {
            rhsButtonText = rhsGpChairModel.chalStatus;
            if (rhsGpChairModel.playerStatus === "Bust") {
                showRhsButton = true;
                rhsButtonClass = "sitOut-bg";
                rhsButtonText = "Burst";
            } else if (rhsGpChairModel.playerStatus === "SitOut") {
                showRhsButton = true;
                rhsButtonClass = "sitOut-bg";
                rhsButtonText = "Sit Out";
            } else if (rhsGpChairModel.playerStatus === "Fold") {
                showRhsButton = true;
                rhsButtonClass = "fold-bg";
                rhsButtonText = "Fold";
            } else if (rhsGpChairModel.chalStatus === "Check") {
                showRhsButton = true;
                rhsButtonClass = "check-bg";
            } else if (rhsGpChairModel.chalStatus === "Call") {
                showRhsButton = true;
                rhsButtonClass = "call-bg";
            } else if (rhsGpChairModel.chalStatus === "Raise") {
                showRhsButton = true;
                rhsButtonClass = "raise-bg";
            } else if (rhsGpChairModel.chalStatus === "AllIn") {
                showRhsButton = true;
                rhsButtonClass = "allin-bg";
            } else if (rhsGpChairModel.chalStatus === "Fold") {
                showRhsButton = true;
                rhsButtonClass = "fold-bg";
            }
        }

        // var showRightButton = false;
        // var rightButtonClass = "";
        // var rightButtonText = "";
        // if (gpChairModel && gpChairModel.playerModel) {
        // 	if (gpChairModel.playerStatus === "WaitingForBigBlind") {
        // 		showRightButton = true;
        // 		rightButtonClass = "waitingBlindStatus-bg";
        // 		rightButtonText = "Waiting For BB";
        // 	} else if (gpChairModel.playerStatus === "AutoPostBigBlind") {
        // 		showRightButton = true;
        // 		rightButtonClass = "waitingBlindStatus-bg";
        // 		rightButtonText = "Auto Post BB";
        // 	} else if (gpChairModel.leaveSeat) {
        // 		showRightButton = true;
        // 		rightButtonClass = "leaving-bg";
        // 		rightButtonText = "Leaving Chair";
        // 	} else if (gpChairModel.sitOut && gpChairModel.playerStatus !== "SitOut" && gpChairModel.playerStatus !== "Burst") {
        // 		showRightButton = true;
        // 		rightButtonClass = "sittingout-bg";
        // 		rightButtonText = "Sitting Out";
        // 	} else if ((gpChairModel.playerStatus === "SitOut" || gpChairModel.playerStatus === "Burst") && !gpChairModel.sitOut) {
        // 		showRightButton = true;
        // 		rightButtonClass = "sittingback-bg";
        // 		rightButtonText = "Sitting Back";
        // 	}
        // }

        return (
            <div className="chair-btn-div">
                {showLhsButton && (
                    <a href="#" className={"btn-sm chair-btn lhsButton " + lhsButtonClass}>
                        {lhsButtonText}
                    </a>
                )}
                {showRhsButton && (
                    <a href="#" className={"btn-sm chair-btn rhsButton " + rhsButtonClass}>
                        {rhsButtonText}
                    </a>
                )}
            </div>
        );
    };

    const drawPlayerObject = () => {
        let myChairIndex = getMyChairIndex();
        return (
            <div style={{ display: "block" }}>
                {drawTossCardsView()}
                {drawMyCardsView()}
                {drawMyRhsCardsView()}
                {drawChairPotAmountAndStake()}
                <div className="profile">
                    <div className=" position-relative ">
                        <div className="profile-box">
                            <img className="profile-img" src={gpChairModel.playerModel.avatar} />
                            {/* You Button */}
                            {/* {gpChairModel.index === myChairIndex && <span className="profile-buler">YOU</span>} */}
                        </div>
                        {gpChairModel.playerModel.playerId + "" === loggedInPlayer.playerId + "" ? (
                            <video id={`local-stream`} className="stream local-stream" autoPlay muted />
                        ) : (
                            <div id={`remote-stream-${gpChairModel.playerModel.playerId}`} className={`stream remote-stream`}></div>
                        )}
                    </div>
                </div>
                {showPlayerStatusButton()}
                <div className=" md-card player md-dance-theme flex align-items-center">
                    <div className="d-flex w-100">
                        <div className="stack md-caption border-right">{chipsUtils.getFormattedChipsTitle(gpChairModel?.playerModel?.playBalance)}</div>
                        <div className="stack md-caption ">{chipsUtils.getFormattedChipsTitle(rhsGpChairModel?.playerModel?.playBalance)}</div>
                    </div>
                    <a href="#" className="name md-title md-button md-dance-theme">
                        {gpChairModel.playerModel.visibleName}
                        {gpChairModel.index === myChairIndex && <span className="profile-buler"> (YOU)</span>}
                    </a>
                    {playerHandResult()}
                    {/* Dealer Button */}
                    {gpChairModel.index === gpTableModel.dealerButtonChairId && <button className="tooltip md-fab md-mini">D</button>}
                    {/* You Button */}
                    {showYouButton && <button className="md-fab md-mini you-indication md-button">You</button>}
                    {/* Small Blind Button */}
                    {showSmallBlindButton && <button className="md-fab md-mini btn-sb md-button">SB</button>}
                    {/* Big Blind Button */}
                    {showBigBlindButton && <button className="md-fab md-mini btn-bb md-button">BB</button>}
                    {/* <a href="#" className="btn btn-check">Check</a> */}
                </div>
                <div className="progressBar" id={gpChairModel.index + "_" + "progressBar"} style={{ animationFillMode: "forwards" }}>
                    <div className="bar" />
                </div>
                {/* <div className="md-display-1 action-performed active">Check</div> */}
            </div>
        );
    };

    return indexClass != null ? (
        <div className={"seat layout-align-center-center layout-row taken sorted-index" + indexClass}>
            {gpChairModel !== undefined && (gpChairModel.playerModel === undefined || gpChairModel.playerModel === null) ? drawTakeSeatButton() : ""}
            {gpChairModel !== undefined && gpChairModel.playerModel !== undefined && gpChairModel.playerModel !== null ? drawPlayerObject() : ""}
            {gpChairModel === undefined ? drawTakeSeatButton() : ""}
        </div>
    ) : (
        <></>
    );
};

export default Chair;
