import React from 'react'
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";

const FooterComponent = () => {
    const [websiteData, setWebsiteData] = useState(null)
    useEffect(() => {
        const cookies = new Cookies();
        setWebsiteData(cookies.get('webData'))
    }, []);
    return (
        <>
            <footer className="">
                <div className="container">
                    <div className="row ">
                        <div className=" col-md-4 logo-img">
                            <img src={websiteData?.logoIcon} style={{ width: "145px" }} alt="Image" />
                        </div>
                        <div className=" col-md-8 col-sm-8">
                            <div className=" ">
                                <div className="social-icons mt-0">
                                    <ul className="icons-list list-unstyled ">
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/learn">How to Play</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/term-conditions">Term &amp; Conditions</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQ</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sopport-div text-center py-4 mt-3">
                        <p className="mb-0">
                            <b className="ml-3 text-white">
                                <span className="mr-2">
                                    <img src="assets/img/company_logo.png" style={{ height: '36px' }} alt="Image" />
                                </span>
                            </b>
                        </p>
                    </div>
                    <div className="copright py-4 text-center">
                        <p className="mb-0" style={{ color: "#595d7c" }}>
                            @ <a href="https://kpis.in" target={'_blank'}>KPIS</a> all rights reserved
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterComponent