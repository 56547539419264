import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Loader from "../Common/Loader";
import Slider from "react-slick";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function NextArrow(props) {
    const { className, style, onClick } = props;
    return <img src="gamePlayAssets/img/right-arrow.png" className={`slick-next ${className}`} onClick={onClick} />;
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <img src="gamePlayAssets/img/right-arrow.png" className={`slick-prev ${className}`} onClick={onClick} />;
}

const ManageProfile = ({ handleRefresh }) => {
    const [loginData, setLoginData] = useState(new Cookies().get("pokerLoginData"));
    const [loading, setLoading] = useState(false);
    const [avatarList, setAvatarList] = useState([]);
    const [aavatar, setAavatar] = useState("");
    const [updatedImage, setUpdatedImage] = useState(null);
    const [selectedAvatar, setSelectedAvatar] = useState(loginData?.photoImageUrl);
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [avatarListToBuy, setAvatarListToBuy] = useState([]);
    const [buynowAvatar, setBuynowAvatar] = useState([]);

    const cookies = new Cookies();
    const fileRef = useRef(null);

    const handleLogout = () => {
        const cookies = new Cookies();
        cookies.remove("pokerLoginData");
        cookies.remove("pokerAuth");
        window.location.href = "/";
    };

    var settings = {
        dots: false,
        infinite: false,

        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,
        slidesToShow: 4,
        // slidesToScroll: 1,
        swipeToSlide: true,
    };
    useEffect(() => {
        getUserProfile();
    }, []);

    useEffect(() => {
        if (!loginData && cookies.get("pokerLoginData")) {
            getUserProfile();
            getAvtarList();
        }
    }, [loginData, cookies]);

    // useEffect(() => {
    //     if (cookies.get('pokerLoginData')) {
    //         // getAvtarList();
    //     }
    // }, [cookies]);
    // useEffect(() => {
    //     if (!cookies.get('pokerLoginData')) {
    //         setLoginData(null);
    //         cookies.remove("pokerAuth");
    //         window.location.href = "/";
    //     } else {
    //         getUserProfile();
    //         getAvtarList();
    //     }
    // }, [loginData, cookies]);

    const getAvatarListToBuy = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodGetAvatarListToBuy,
            { Authorization: cook !== undefined ? "Basic " + cook : "" },
            (response) => {
                const avatarListToBuy = response.responsePacket;
                setAvatarListToBuy(avatarListToBuy);
            },
            (error) => {}
        );
    };

    const getUserProfile = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodGetProfile,
            {
                Authorization: cook !== undefined ? "Basic " + cook : "",
            },
            (response) => {
                setLoginData(response.responsePacket);
                getAvtarList();
                Cookies().set("pokerLoginData", loginData);
            },
            (error) => {}
        );
    };

    const buyAvatar = () => {
        const cook = cookies.get("pokerAuth");
        const Data = {
            recordId: buynowAvatar.recordId,
        };
        if (buynowAvatar.recordId) {
            ApiCaller.callPostApi(
                url.MethodBuyAvatar.replace("{uuid}", Data.recordId),
                Data,
                { Authorization: cook !== undefined ? "Basic " + cook : "" },
                (response) => {
                    setBuynowAvatar(Data);
                    getAvtarList();
                    handleRefresh();
                    toast.success(response.message || "Avatar has been added to your list!");
                },
                (error) => {}
            );
        } else {
        }
    };

    const getAvtarList = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodGetMyAvtarList,
            { Authorization: cook !== undefined ? "Basic " + cook : "" },
            (response) => {
                const avatarListResponse = response.responsePacket;
                if (loginData && avatarListResponse.length > 0) {
                    const selectedAvatarIndex = avatarListResponse.findIndex((avatar) => avatar.avatarImageUrl === loginData.photoImageUrl);
                    if (selectedAvatarIndex !== -1) {
                        const updatedAvatarList = [avatarListResponse[selectedAvatarIndex], ...avatarListResponse.slice(0, selectedAvatarIndex), ...avatarListResponse.slice(selectedAvatarIndex + 1)];
                        setAvatarList(updatedAvatarList);
                        setUpdatedImage(avatarListResponse[selectedAvatarIndex].avatarImageUrl);
                    } else {
                        setAvatarList(avatarListResponse);
                        setUpdatedImage(avatarListResponse[0].avatarImageUrl);
                    }
                } else {
                    setAvatarList(avatarListResponse);
                    setUpdatedImage(avatarListResponse[0].avatarImageUrl);
                }
            },
            (error) => {}
        );
    };

    const setAvatar = () => {
        const cook = cookies.get("pokerAuth");
        const Data = {
            recordId: selectedRecordId,
        };
        ApiCaller.callPostApi(
            url.MethodSetAvatar.replace("{uuid}", Data.recordId),
            Data,
            { Authorization: cook !== undefined ? "Basic " + cook : "" },
            (response) => {
                setAavatar(Data);
                setLoginData({
                    ...loginData,
                    photoImageUrl: selectedAvatar,
                });
                getUserProfile();
                handleRefresh();
            },
            (error) => {}
        );
    };

    // const handleSetUpdatedImage = (selectedImageUrl, recordId) => {
    //     if (selectedAvatar !== selectedImageUrl) {
    //         setSelectedAvatar(selectedImageUrl);
    //         setSelectedRecordId(recordId);

    //         const updatedAvatars = avatarList?.map((avatar) => ({
    //             ...avatar,
    //             isSelected: avatar.avatarImageUrl === selectedImageUrl,
    //         }));

    //         setAvatarList(updatedAvatars);
    //     }
    // };

    // Inside the handleSetUpdatedImage function
    const handleSetUpdatedImage = (selectedImageUrl, recordId) => {
        if (selectedAvatar !== selectedImageUrl) {
            // Generate a unique query parameter
            const timestamp = Date.now();
            // const updatedImageUrl = `${selectedImageUrl}?${timestamp}`;

            let updatedImageUrl = "";
            if (selectedImageUrl.includes("?")) {
                updatedImageUrl = `${selectedImageUrl}&v=${timestamp}`;
            } else {
                updatedImageUrl = `${selectedImageUrl}?v=${timestamp}`;
            }

            setSelectedAvatar(updatedImageUrl);
            setSelectedRecordId(recordId);

            const updatedAvatars = avatarList?.map((avatar) => ({
                ...avatar,
                isSelected: avatar.avatarImageUrl === updatedImageUrl,
            }));

            setAvatarList(updatedAvatars);
        }
    };

    const handleSelectAvatar = (avatar) => {
        if (avatar.owned) {
            toast.success("This avatar is already owned.");
            return;
        }
        setBuynowAvatar({ ...avatar });
    };

    // const handleProfileImageChange = (fileEvent) => {
    //     const { files } = fileEvent.target;
    //     if (files && files[0]) {
    //         var reader = new FileReader();
    //         reader.onload = function (event) {
    //             const { result } = event.target;
    //             fileRef.current.setAttribute("src", result);
    //             setProfileData({
    //                 fileName: files[0].name,
    //                 fileBase64: result,
    //             });
    //             handleChangeAvatar(result);
    //         };
    //         reader.readAsDataURL(files[0]);
    //     }
    // };

    // const handleSetAvatar = () => {
    //     if (profileData.fileBase64) {
    //         callPostApi(Upload_File_POST, profileData, {}, (fileResponse) => {
    //             const toastId = toast.loading("Uploading...", { id: "uploadMsg" });
    //             if (fileResponse.errorCode === NO_ERROR) {
    //                 setProfileData({ fileName: "", fileBase64: "" });
    //                 handleUpdateAvatar(fileResponse.responsePacket);
    //                 toast.success(fileResponse.message, { id: "successMsg" });
    //             } else {
    //                 toast.error(fileResponse.message, { id: "errorMsg" });
    //             }
    //         });
    //     } else {
    //         handleUpdateAvatar(updatedImage);
    //     }
    // };

    const uniqueCategories = Array.from(new Set(avatarListToBuy?.map((avatar) => avatar.avatarCategoryTitle)));

    return (
        <>
            <div>
                <section className="main-content">
                    <div className="container">
                        <div className="text-center pt-5">
                            <h2 className="text-white">Manage Profile</h2>
                            <p>
                                {loginData?.email} •{" "}
                                <span className="cursor ml-2" data-toggle="modal" data-target="#logoutmodal" style={{ cursor: "pointer" }}>
                                    Logout
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="container">
                        <div className="contant-div Profile-tabs">
                            <ul className="nav nav-tabs border-radius-4" style={{ position: "relative", top: "-20px" }}>
                                <li className="nav-item">
                                    <a href="#ManageProfile" className="nav-link active" data-bs-toggle="tab">
                                        Manage Profile
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content text-left">
                                <div className="tab-pane fade show active" id="ManageProfile">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="profile-pic">
                                                <input id="file" type="file" />
                                                <img src={loginData?.photoImageUrl} id="output" width={200} alt="Image" />
                                            </div>

                                            <h6 className="text-center mt-3 cursor-pointer" data-toggle="modal" data-target="#avatarModal" onClick={getAvtarList}>
                                                Change avatar
                                            </h6>
                                        </div>
                                        <div className=" col-md-8">
                                            <div>
                                                <h4 className="text-white mt-3">Personal Information</h4>
                                                <div className="form-group mt-3">
                                                    <label>Email Address</label>
                                                    <label className="form-control" id="exampleInputEmail1">
                                                        {" "}
                                                        {loginData?.email}{" "}
                                                    </label>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>Mobile Number</label>
                                                    <label className="form-control" id="exampleInputEmail1">
                                                        {" "}
                                                        {loginData?.mobile}{" "}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="Wallet">
                                    <div className="row">
                                        <div className=" col-md-4">
                                            <div className="wallet-left-box text-center">
                                                <a href="#" className="btn custom-btn text-white d-block">
                                                    + Add money to wallet
                                                </a>
                                            </div>
                                        </div>
                                        <div className=" col-md-8">
                                            <div className="wallet-right-box">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 className="text-white">All Transactions Details</h4>
                                                </div>
                                                <table className="table mt-2">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col" />
                                                            <th scope="col">Transaction</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-white"></tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade" id="logoutmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header border-none">
                            <button type="button" className="btn-close text-white" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0 text-center">
                            <h4 className="text-white mt-3">LogOut</h4>
                            <p>Are you sure to logout ?</p>
                            <div className="form-group">
                                <a className="btn custom-btn text-white cursor" onClick={handleLogout}>
                                    Yes
                                </a>
                                <a href="#" className="btn custom-btn text-white" data-dismiss="modal" aria-label="Close">
                                    NO
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="avatarModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom justify-content-center ">
                            <h5 className="fs-18 text-center d-block justify-content-center mb-0">Change profile picture</h5>
                            <button type="button" className="close text-white position-absolute end-0 top-0 m-0 opacity-100" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 text-center">
                            <div className=" pb-3">
                                <div className="my-3 d-flex align-items-center justify-content-center">
                                    <div className="avtaar-user">
                                        <img src={selectedAvatar || loginData?.photoImageUrl} id="output" width={200} alt="Image" />
                                    </div>
                                </div>
                                <Slider {...settings}>
                                    {avatarList?.map((avatar, i) => (
                                        <div
                                            className={`item ${avatar.isSelected || (i === 0 && !avatarList.some((av) => av.isSelected)) ? "selected" : ""}`}
                                            key={i}
                                            onClick={() => handleSetUpdatedImage(avatar.avatarImageUrl, avatar.recordId)}
                                        >
                                            <div className="item-box">
                                                <div className="img-width cursor-pointer avatarImg">
                                                    <img src={avatar.avatarImageUrl} alt={avatar.avatarName} className="mx-auto" />
                                                </div>
                                                <p className="text-center font-size-10 mt-2 mb-0 text-white font-weight-600">{avatar.avatarName}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center gap-3 pb-3">
                            <button className="btn custom-btn text-white cursor px-3" data-toggle="modal" data-target="#avatarCategoryList" onClick={getAvatarListToBuy}>
                                {" "}
                                {/*onClick={handleSetAvatar}*/}
                                <span className="">Buy Avatar</span>
                            </button>
                            <button className="btn custom-btn text-white cursor px-5" aria-label="Close" data-dismiss="modal" onClick={setAvatar}>
                                {" "}
                                {/*onClick={handleSetAvatar}*/}
                                <span className="">Set</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="avatarCategoryList" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom border-top-0  justify-content-center ">
                            <h5 className="fs-18 text-center d-block justify-content-center mb-0">Avatar Category List</h5>
                            <button type="button" className="close text-white position-absolute end-0 top-0 m-0 opacity-100" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 overflow-auto" style={{ height: "430px" }}>
                            {uniqueCategories?.map((category) => {
                                const avatarsInCategory = avatarListToBuy.filter((buyAvatar) => buyAvatar.avatarCategoryTitle === category);

                                if (avatarsInCategory.length === 1) {
                                    const singleAvatar = avatarsInCategory[0];
                                    return (
                                        <div key={singleAvatar.recordId}>
                                            <h6 className="fs-14 fw-400 text-white my-3">{category}</h6>
                                            <div className={`item selected-avtaar ${singleAvatar.recordId === buynowAvatar.recordId ? " p-2" : ""}`} onClick={() => handleSelectAvatar(singleAvatar)}>
                                                <div className="item-box ">
                                                    <div className="img-width cursor-pointer avatarImg">
                                                        <img src={singleAvatar.avatarImageUrl} alt={singleAvatar.avatarName} className="mx-auto" />
                                                        <div className="ps-4 pt-2 text-left">{singleAvatar.avatarName}</div>
                                                        <div className="ps-4 pt-2 text-left">
                                                            {singleAvatar.avatarPrice} {singleAvatar.avatarCurrency}
                                                        </div>
                                                    </div>
                                                    {singleAvatar.owned && <div className="ownedAvatar d-flex align-items-center justify-content-center"> OWNED </div>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <>
                                        <div key={category}>
                                            <h6 className="fs-14 fw-400 text-white my-3">{category}</h6>
                                            <Slider {...settings}>
                                                {avatarsInCategory?.map((filteredAvatar, i) => (
                                                    <div className={`item ${filteredAvatar.isSelected ? "selected" : ""}`} key={i} onClick={() => handleSelectAvatar(filteredAvatar)}>
                                                        <div className={`item-box ${filteredAvatar.recordId === buynowAvatar.recordId ? "selected-border" : ""}`}>
                                                            <div className="img-width cursor-pointer avatarImg">
                                                                <img src={filteredAvatar.avatarImageUrl} alt={filteredAvatar.avatarName} className="mx-auto" />
                                                                <div className="ps-4 pt-2 text-center">{filteredAvatar.avatarName}</div>
                                                                <div className="ps-4 pt-2 text-center">
                                                                    {filteredAvatar.avatarPrice} {filteredAvatar.avatarCurrency}
                                                                </div>
                                                            </div>
                                                            {filteredAvatar.owned && <div className="ownedAvatar d-flex align-items-center justify-content-center"> OWNED </div>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </>
                                );
                            })}
                            <div className="text-center mt-3">
                                <button className="btn custom-btn text-white cursor cursor px-5" aria-label="Close" data-dismiss="modal" onClick={buyAvatar}>
                                    Buy Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageProfile;
