import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { MethodGetTableId } from "../../Const";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";

const HeaderBar = (props) => {
    const cookies = new Cookies();
    const [loggedInPlayer, setLoggedInPlayer] = useState(null);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [socketCaller, setSocketCaller] = useState(null);

    const getTableTheme = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodGetTableId,
            { Authorization: cook !== undefined ? "Basic " + cook : "" },
            (response) => {
                const tableTheme = response.responsePacket;
                setTableThemeOnUi(tableTheme);
            },
            (error) => {}
        );
    };

    const setTableTheme = (tTheme) => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callPostApi(url.MethodSetTableId.replace("{tableId}", tTheme), {}, { Authorization: cook !== undefined ? "Basic " + cook : "" }, (response) => {
            setTableThemeOnUi(tTheme);
        });
    };

    const setTableThemeOnUi = (tableTheme) => {
        let dTheme = "Black-gold";
        if (!(tableTheme === null || tableTheme === undefined || tableTheme === "")) {
            dTheme = tableTheme;
        }
        $("#page-top").removeClass();
        $("#page-top").addClass(dTheme);
    };

    useEffect(() => {
        setLoggedInPlayer(props.loggedInPlayer);
        setSocketCaller(props.socketCaller);
        // setTableId(props.tableId);
        setGpTableModel(props.gpTableModel);

        initPageJs();
        getTableTheme();
    }, [props.loggedInPlayer, props.socketCaller, props.tableId, props.gpTableModel]);

    const initPageJs = () => {
        $('[data-toggle="offcanvas"], #navToggle').off("click");
        $('[data-toggle="offcanvas"], #navToggle').on("click", function () {
            $(".offcanvas-collapse").toggleClass("open");
        });

        $("#navToggleBack").off("click");
        $("#navToggleBack").on("click", function () {
            $("#navToggle").trigger("click");
        });

        $(".nav-link").off("click");
        $(".nav-link").on("click", function () {
            $("#navToggle").trigger("click");
        });
    };

    // var myChairIndex = -1;
    // const getMyChairIndex = () => {
    //     myChairIndex = -1;
    //     if (gpTableModel === null || gpTableModel === undefined) {
    //         return;
    //     }
    //     for (let key = 0; key < gpTableModel.chairs.length; key++) {
    //         var playerModel = gpTableModel.chairs[key].playerModel;
    //         if (playerModel != null && playerModel !== undefined && playerModel.playerId === loggedInPlayer.playerId) {
    //             myChairIndex = key;
    //             break;
    //         }
    //     }
    // }

    const handleLeaveTable = (e) => {
        socketCaller?.callLeaveSeat();
        socketCaller?.callUnJoinTable();
        // window.open("/holdem-games", "_self");
        // window.close();
    };

    return (
        <nav>
            <div className="md-menu-bar">
                <div className="md-toolbar-tools justify-content-between custom-sidebar">
                    <nav className="navbar navbar-offcanvas navbar-dark ">
                        <button className="navbar-toggler d-block" type="button" id="navToggle">
                            <img src="gamePlayAssets/img/metro-home.svg" />
                        </button>
                        <div className="navbar-collapse offcanvas-collapse">
                            <button className="navbar-toggler d-block text-black closebtn" type="button" id="navToggleBack" style={{ color: "#000" }}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <ul className="navbar-nav mr-auto mt-4 pt-2">
                                {/* <li className="nav-item active">
                                    <Link className="nav-link"
                                        onClick={socketCaller?.callGetTableDetail}> Table Details  </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link"
                                        onClick={socketCaller?.callJoinTable}> Join Table </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link"
                                        onClick={socketCaller?.callUnJoinTable}> UnJoin Table </Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link"
                                        onClick={socketCaller?.disconnectSocket}> Disconnect Socket </Link>
                                </li> */}

                                {loggedInPlayer?.playerId > 0 ? (
                                    <li className="nav-item ">
                                        <div className="nav-link" style={{ cursor: "pointer" }} onClick={socketCaller?.callLeaveSeat}>
                                            {" "}
                                            Leave Seat{" "}
                                        </div>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/holdem-games" onClick={handleLeaveTable}>
                                        {" "}
                                        Leave Table{" "}
                                    </Link>
                                </li>
                                <li className="nav-item ">
                                    <span className="theme-link">
                                        <span className="d-block text-black">Theme Change</span>
                                        <span className="themeChange">
                                            <span>
                                                <img src="gamePlayAssets/img/themeBlackGold.jpg" alt="themeBlackGold" onClick={() => setTableTheme("Black-gold")} />
                                            </span>
                                            <span>
                                                <img src="gamePlayAssets/img/themeBlueGold.jpg" alt="themeBlueGold" onClick={() => setTableTheme("Blue-gold")} />
                                            </span>
                                            <span>
                                                <img src="gamePlayAssets/img/themeGreenGold.jpg" alt="themeGreenGold" onClick={() => setTableTheme("Green-gold")} />
                                            </span>
                                        </span>
                                        <span className="themeChange mt-2">
                                            <span>
                                                <img src="gamePlayAssets/img/themeFuturistic.jpg" alt="themeFuturistic" onClick={() => setTableTheme("Futuristic-town")} />
                                            </span>
                                            <span>
                                                <img src="gamePlayAssets/img/themeSea.jpg" alt="themeSea" onClick={() => setTableTheme("Sea-town")} />
                                            </span>
                                            <span>
                                                <img src="gamePlayAssets/img/themeForest.png" alt="themeForest" onClick={() => setTableTheme("Fantasy-forest")} />
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </nav>
    );
};

export default HeaderBar;
