import React from "react";
export const FilterIcon = () => {
    return (
        <>
            <svg width={11} height={21} viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.60059 0.638916L9.93071 8.88892H1.27046L5.60059 0.638916Z" fill="#FFDA8C" />
                <path d="M5.60059 20.6389L1.27046 12.3889L9.93071 12.3889L5.60059 20.6389Z" fill="#FFDA8C" />
            </svg>
        </>
    );
};
