import React from 'react'
const AboutUsComponent = () => {
    return (
        <>
            <div>
                <section className="main-content">
                    <div className="container">
                        <div className="text-center pt-5">
                            <h2 className="text-white">About Us</h2>
                            <p>Home &gt; About</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="contant-div text-center px-4 py-5">
                            <div className=" row m-0">
                                <div className=" col-lg-4 col-md-6 col-sm-6">
                                    <img src="assets/img/svg/01.svg" alt='01.svg' />
                                    <h5 className="text-white mt-3">Register</h5>
                                    <p>
                                        The world series of poker is the
                                        <br /> most prestigious series of
                                        <br /> poker tournaments
                                    </p>
                                </div>
                                <div className=" col-lg-4 col-md-6 col-sm-6">
                                    <img src="assets/img/svg/02.svg" alt='02.svg' />
                                    <h5 className="text-white mt-3">Get Ready</h5>
                                    <p>
                                        The world series of poker is the
                                        <br /> most prestigious series of
                                        <br /> poker tournaments
                                    </p>
                                </div>
                                <div className=" col-lg-4 col-md-6 col-sm-6">
                                    <img src="assets/img/svg/03.svg" alt='03.svg' />
                                    <h5 className="text-white mt-3">Enjoy</h5>
                                    <p>
                                        The world series of poker is the
                                        <br /> most prestigious series of
                                        <br /> poker tournaments
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dolor-div">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 large-6"></div>
                            <div className=" col-lg-6 ">
                                <h2 className="text-white">Lorem ipsum dolor sit amet,</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                                    est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                                </p>
                                <p>
                                    Excepteur sint occaecat, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Bibendum est
                                    ultricies
                                </p>
                                <a className="btn custom-btn text-white" href="#">
                                    Play Now
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-center stand-div">
                    <div className=" container">
                        <h2 className="text-white">WHAT WE STAND FOR</h2>
                        <p>
                            Our mission is to make games that grown-ups love to play.you are front
                            and
                            <br /> center in our decision-making process for every game we make.
                        </p>
                        <div className="row">
                            <div className=" col-10 mx-auto text-left">
                                <div className="row">
                                    <div className=" col-lg-6 col-md-6 col-sm-6">
                                        <div className="contant-box d-flex p-3">
                                            <div className="mr-3">
                                                <div className="img-div">
                                                    <img src="assets/img/about-us.png" alt='about-us.png' />
                                                </div>
                                            </div>
                                            <p>
                                                We believe in creating games that are inclusive, accessible,
                                                and age appropriate
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6">
                                        <div className="contant-box d-flex p-3">
                                            <div className="mr-3">
                                                <div className="img-div">
                                                    <img src="assets/img/about-us.png" alt='about-us.png' />
                                                </div>
                                            </div>
                                            <p>
                                                We believe in creating games that are inclusive, accessible,
                                                and age appropriate
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6">
                                        <div className="contant-box d-flex p-3">
                                            <div className="mr-3">
                                                <div className="img-div">
                                                    <img src="assets/img/about-us.png" alt='about-us.png' />
                                                </div>
                                            </div>
                                            <p>
                                                We believe in creating games that are inclusive, accessible,
                                                and age appropriate
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-6">
                                        <div className="contant-box d-flex p-3">
                                            <div className="mr-3">
                                                <div className="img-div">
                                                    <img src="assets/img/about-us.png" alt='about-us.png' />
                                                </div>
                                            </div>
                                            <p>
                                                We believe in creating games that are inclusive, accessible,
                                                and age appropriate
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row dolor-sit">
                            <div className="col-lg-7 col-md-7 col-sm-7 pr-0">
                                <div className="left-div">
                                    <div>
                                        <h2 className="text-white">Lorem ipsum dolor sit amet,</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt.
                                        </p>
                                        <ul>
                                            <li>There are positive gaming environment</li>
                                            <li>We have zero tolerance for hate speech and trolls</li>
                                            <li>We have zero tolerance for hate speech and trolls</li>
                                            <li>We have zero tolerance for hate speech and trolls</li>
                                            <li>We ipsum dolor sit amet, consectetur adipiscing</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-lg-5 col-md-5 col-sm-5 pl-0">
                                <div className="right-div">
                                    <img src="assets/img/dolar-sit.png" width="100%" alt='dolar-sit.png' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AboutUsComponent