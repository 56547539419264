import React, { useState, useEffect } from "react";
import Chair from "./ChairSingleHand";
import Card from "../GamePlay/Card";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";
import ApiCaller from "../../Api/ApiCaller";
import { MethodWebsiteDetail } from "../../Const";

const Table = (props) => {
    const [loggedInPlayer, setLoggedInPlayer] = useState(null);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [socketCaller, setSocketCaller] = useState(null);
    const chipsUtils = new ChipsUtils();

    useEffect(() => {
        setLoggedInPlayer(props.loggedInPlayer);
        setSocketCaller(props.socketCaller);
        // setTableId(props.tableId);
        // PreFlop / Flop / Turn / River
        if (props.gpTableModel && props.gpTableModel.tableTurnStatus !== props.gpTableModel.tableTurnStatus) {
            if (props.gpTableModel.tableTurnStatus === "Flop") {
                console.log("==================Flop OPEN");
            } else if (props.gpTableModel.tableTurnStatus === "Turn") {
                console.log("==================Turn OPEN");
            } else if (props.gpTableModel.tableTurnStatus === "River") {
                console.log("==================River OPEN");
            } else {
            }
        } else {
        }
        setGpTableModel(props.gpTableModel);
    }, [props.loggedInPlayer, props.socketCaller, props.tableId, props.gpTableModel]);

    const getChairs = () => {
        if (gpTableModel === null) {
            return <></>;
        }

        var indexClasses = [];
        if (gpTableModel.gameConfig.maxPlayers === 2) {
            indexClasses = [1, 6];
        } else if (gpTableModel.gameConfig.maxPlayers === 3) {
            indexClasses = [1, 4, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 4) {
            indexClasses = [1, 4, 6, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 5) {
            indexClasses = [1, 4, 5, 7, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 6) {
            indexClasses = [1, 4, 5, 6, 7, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 7) {
            indexClasses = [1, 3, 4, 5, 7, 8, 9];
        } else if (gpTableModel.gameConfig.maxPlayers === 8) {
            indexClasses = [1, 3, 4, 5, 6, 7, 8, 9];
        } else if (gpTableModel.gameConfig.maxPlayers === 9) {
            indexClasses = [1, 2, 3, 4, 5, 7, 8, 9, 10];
        } else if (gpTableModel.gameConfig.maxPlayers === 10) {
            indexClasses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        }
        var updatedChairIndex = [];
        var myChairIndex = -1;
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel !== null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
            }
            updatedChairIndex.push(key);
        }

        // //// TO Switch Position of Chair Where My Chair is on bottom side.
        // if (myChairIndex > 0) {
        //     updatedChairIndex = [];
        //     for (let cIndex = myChairIndex; cIndex < gpTableModel.chairs.length; cIndex++) {
        //         updatedChairIndex.push(gpTableModel.chairs[cIndex].index);
        //     }
        //     for (let cIndex = 0; cIndex < myChairIndex; cIndex++) {
        //         updatedChairIndex.push(gpTableModel.chairs[cIndex].index);
        //     }
        // }
        return (
            <>
                {indexClasses.map((iClass, key) => {
                    return (
                        <Chair
                            key={iClass}
                            indexClass={iClass}
                            loggedInPlayer={loggedInPlayer}
                            chairIndex={key}
                            socketCaller={socketCaller}
                            gpTableModel={gpTableModel}
                            gpChairModel={gpTableModel.chairs[updatedChairIndex[key]]}
                            showTakeStakeOnTakeSeatModel={props.showTakeStakeOnTakeSeatModel}
                        />
                    );
                })}
            </>
        );
    };

    const getTableChipsAndPot = () => {
        if (gpTableModel === null) {
            return <></>;
        }
        if (gpTableModel.tableStatus === "Turn" || gpTableModel.tableStatus === "Result") {
            return (
                <div className="pot layout-align-center-center layout-column mb-3">
                    <div className="d-flex">
                        <div className="custom-chips text-center mr-1">
                            <div className="d-flex justify-content-center">
                                {chipsUtils.getChipsStake(gpTableModel !== null && gpTableModel.totalPotBalance !== null ? gpTableModel.totalPotBalance : 0)}
                            </div>
                            <div className=" text-white mt-1">
                                <span>
                                    {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.totalPotBalance !== null ? gpTableModel.totalPotBalance : 0)}
                                    {gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T"}
                                </span>
                            </div>
                        </div>
                        <div className="custom-chips text-center ml-1">
                            <div className="d-flex justify-content-center">
                                {chipsUtils.getChipsStake(
                                    gpTableModel !== null && gpTableModel.totalPotBalance !== null
                                        ? chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.totalPotBalance)
                                        : 0
                                )}
                            </div>
                            <div className=" text-white mt-1">
                                <span>
                                    {chipsUtils.getFormattedChipsTitle(
                                        gpTableModel !== null && gpTableModel.totalPotBalance !== null
                                            ? chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.totalPotBalance)
                                            : 0
                                    )}
                                    {gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className=" align-items-center">
                        <div className="pot-box">
                            <span>
                                Total Pot : {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.tableChairPotBalance !== null ? gpTableModel.tableChairPotBalance : 0)}
                                {gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T"} |{" "}
                                {chipsUtils.getFormattedChipsTitle(
                                    gpTableModel !== null && gpTableModel.tableChairPotBalance !== null
                                        ? chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpTableModel.tableChairPotBalance)
                                        : 0
                                )}
                                {gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G"}
                            </span>
                            <br />
                        </div>
                    </div>
                </div>
            );
        }
        return <></>;
    };

    const getTableCards = () => {
        return (
            <>
                {[...Array(5)] &&
                    [...Array(5)].map((number, index) => {
                        var cardModel = null;
                        if (gpTableModel !== null && gpTableModel.tableCards !== null) {
                            if (gpTableModel.tableCards[index] !== undefined) {
                                cardModel = gpTableModel.tableCards[index];
                            }
                        }
                        return <Card key={"TblCard_" + index} cardPosition="table" gpTableModel={gpTableModel} gpCardModel={cardModel} />;
                    })}
            </>
        );
    };

    const getTableCardsOrStatus = () => {
        if (gpTableModel === null) {
            return <></>;
        }
        if (gpTableModel.tableStatus === "Turn" || gpTableModel.tableStatus === "Result") {
            return <div className="community ng-scope layout-align-center-center layout-row center-card">{getTableCards()}</div>;
        }
        if (gpTableModel.tableStatus === "None") {
            return <div className="tableStatus text-white"></div>;
        }
        if (gpTableModel.tableStatus === "WaitingForPlayer") {
            return <div className="tableStatus text-white">Waiting For PLayers</div>;
        }
        if (gpTableModel.tableStatus === "WaitingForGame") {
            return <div className="tableStatus text-white">Waiting For Game</div>;
        }
        if (gpTableModel.tableStatus === "Toss") {
            return <div className="tableStatus text-white">Dealing TOSS</div>;
        }
        if (gpTableModel?.gameConfig.gameType === "BluffGame" && gpTableModel.tableStatus === "BluffActiveScreen") {
            return (
                <div className="tableStatus text-center text-white">
                    Active Screen
                    <br />
                    {/* {callCount(gpTableModel.tableTimer.countDown)} */}
                    New game will begin shortly...
                </div>
            );
        }
        return <></>;
    };

    // let timerObj = null;
    // const callCount = (countDownTimer) => {
    //     function countdown() {
    // 		countDownTimer--;
    //         console.log(countDownTimer)
    // 		if(document.getElementById("countDownTime")){
    // 			document.getElementById("countDownTime").innerHTML = countDownTimer;
    // 		}
    // 	};
    // 	if(timerObj !== null) {
    // 		clearTimeout(timerObj);
    // 	}
    // 	// else if (countDownTimer > 0){
    // 		timerObj = setTimeout(countdown, 1000);
    // 	// }
    // }

    const getResult = () => {
        // console.log("---", gpTableModel, gpTableModel && gpTableModel.tableResultContent);
        return (
            gpTableModel &&
            gpTableModel.tableStatus === "Result" &&
            gpTableModel.tableTurnStatus === "River" &&
            gpTableModel.tableResultContent && (
                <div style={{ color: "white", padding: "5px 0px 0px 0px", fontSize: "18px" }}>
                    <div>{gpTableModel.tableResultContent}</div>
                </div>
            )
        );
    };

    return (
        <>
            <div className="table-center  layout-align-center-center layout-column flex">
                <div className="w-100 layout-align-center-center layout-column flex" style={{ position: "relative", zIndex: "1" }}>
                    <div className="position-absolute mt-5 pt-4 text-center centerLogo" style={{ zIndex: "-1" }}>
                        <img src={props.tableLogoUrl} alt="" />
                    </div>
                    {/* Chips Stake Start */}
                    {getTableChipsAndPot()}
                    {/* Chips Stake End */}

                    {/* Table Cards Start */}
                    {getTableCardsOrStatus()}
                    {/* Table Cards End */}
                    <div className=" align-items-center mt-2 custom-mt text-white d-none">
                        <div className="pot-box mr-2 ">
                            <span>{gpTableModel?.gameSessionId}</span>
                        </div>
                    </div>

                    {/* Chair List */}
                    {getResult()}
                    {getChairs()}
                    {/* Chair List */}
                </div>
            </div>
        </>
    );
};

export default Table;
