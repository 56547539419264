import React from "react";

const TablePlayer = ({ gameDetail }) => {
    // const playerCountArray = [
    //     ...Array.from({ length: gameDetail?.maxPlayers }, (v, i) => i),
    // ];
    let arr;
    let topDots = 0;
    let rightDots = 0;
    let bottomDots = 0;
    let leftDots = 0;
    if (gameDetail?.maxPlayers === 2) {
        topDots = 1;
        bottomDots = 1;
        leftDots = 0;
        rightDots = 0;
        arr = [1, 7];
    } else if (gameDetail?.maxPlayers === 3) {
        topDots = 1;
        bottomDots = 1;
        leftDots = 0;
        rightDots = 1;

        arr = [1, 5, 9];
    } else if (gameDetail?.maxPlayers === 4) {
        topDots = 1;
        bottomDots = 1;
        leftDots = 1;
        rightDots = 1;
        arr = [1, 4, 7, 10];
    } else if (gameDetail?.maxPlayers === 5) {
        topDots = 2;
        bottomDots = 1;
        leftDots = 1;
        rightDots = 1;
        arr = [1, 4, 6, 8, 10];
    } else if (gameDetail?.maxPlayers === 6) {
        topDots = 2;
        bottomDots = 2;
        leftDots = 1;
        rightDots = 1;
        arr = [1, 3, 5, 9, 7, 11];
    } else if (gameDetail?.maxPlayers === 7) {
        topDots = 2;
        bottomDots = 2;
        leftDots = 1;
        rightDots = 2;
        arr = [1, 3, 5, 6, 8, 9, 11];
    } else if (gameDetail?.maxPlayers === 8) {
        topDots = 2;
        bottomDots = 2;
        leftDots = 2;
        rightDots = 2;
        arr = [1, 3, 4, 5, 7, 9, 10, 11];
    } else if (gameDetail?.maxPlayers === 9) {
        topDots = 3;
        bottomDots = 2;
        leftDots = 2;
        rightDots = 2;
        arr = [1, 3, 4, 5, 6, 8, 9, 10, 11];
    } else {
        topDots = 3;
        bottomDots = 3;
        leftDots = 2;
        rightDots = 2;
        arr = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }
    return (
        <div>
            <div className="player-table">
                <span className="inner-box">
                    {gameDetail?.playingPlayers || 0}/{gameDetail?.maxPlayers}
                </span>
                {<span className="player-number">{gameDetail?.playingPlayers}</span>}

                {/* {arr.map((i) => (
                    <span key={i} className={`dot player-${i}`}></span>
                ))} */}
                {/* <span className="dot player-1"></span>
                <span className="dot player-2"></span>
                <span className="dot player-3"></span>
                <span className="dot player-4"></span>
                <span className="dot player-5"></span>
                <span className="dot player-6"></span>
                <span className="dot player-7"></span>
                <span className="dot player-8"></span>
                <span className="dot player-9"></span>
                <span className="dot player-10"></span> */}
                <div className="playerTableList-top">
                    {[...Array(topDots)].map((number, i) => {
                        return (
                            <span className="playerTableListPlayer">
                                <span className="dot"></span>
                            </span>
                        );
                    })}
                </div>
                <div className="playerTableList-right">
                    {[...Array(rightDots)].map((number, i) => {
                        return (
                            <span className="playerTableListPlayer">
                                <span className="dot"></span>
                            </span>
                        );
                    })}
                </div>
                <div className="playerTableList-bottom">
                    {[...Array(bottomDots)].map((number, i) => {
                        return (
                            <span className="playerTableListPlayer">
                                <span className="dot"></span>
                            </span>
                        );
                    })}
                </div>
                <div className="playerTableList-left">
                    {[...Array(leftDots)].map((number, i) => {
                        return (
                            <span className="playerTableListPlayer">
                                <span className="dot"></span>
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TablePlayer;
