import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import HeaderBar from "../GamePlay/HeaderBar";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";
import FaceSuiteUtils from "../GamePlay/Utilities/FaceSuiteUtils";
import SocketCaller from "../GamePlay/Utilities/SocketCaller";
import SocketClient from "../GamePlay/Utilities/SocketClient";
import UrlUtils from "../GamePlay/Utilities/UrlUtils";
import Table from "./TableDoubleHand";
import AgoraRTC from "agora-rtc-sdk-ng";
import ReactDOM from "react-dom/client";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";

const GamePlayDoubleHand = () => {
    const urlUtils = new UrlUtils();
    var urlParamClientId = urlUtils.getUrlParameter("ClientId");
    var urlParamTableId = urlUtils.getUrlParameter("TableId");
    var urlParamUserName = urlUtils.getUrlParameter("U");
    var urlParamSecret = urlUtils.getUrlParameter("S");
    var urlParamUserType = urlUtils.getUrlParameter("UT");

    const appId = "d49fe6b0ca3549c7990838c55d7462e0";
    const token = null;
    const [agoraClient, setAgoraClient] = useState(null);
    const initializeAgora = async () => {
        const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        try {
            const userId = await client.join(appId, urlParamTableId, token, parseInt(loggedInPlayer.playerId));
            setAgoraClient(client);

            client.on("user-published", async (user, mediaType) => {
                // Subscribe to a remote user
                await client.subscribe(user, mediaType).then(() => {
                    if (user.uid !== parseInt(loggedInPlayer.playerId)) {
                        if (mediaType === "video") {
                            // Get `RemoteVideoTrack` in the `user` object.
                            const remoteVideoTrack = user.videoTrack;
                            // // Dynamically create a container in the form of a DIV element for playing the remote video track.
                            const root = ReactDOM.createRoot(document.getElementById(`remote-stream-${user.uid}`));
                            let videoDiv = React.createElement("div", {
                                id: "video-div-" + user.uid,
                                className: "remote-stream",
                            });
                            root.render(videoDiv);
                            setTimeout(function () {
                                user.videoTrack.play(`video-div-${user.uid}`);
                            }, 2000);
                        }
                        if (mediaType === "audio") {
                            // Get `RemoteAudioTrack` in the `user` object.
                            const remoteAudioTrack = user.audioTrack;
                            // Play the audio track. Do not need to pass any DOM element
                            remoteAudioTrack.play();
                        }
                    }
                });
            });
            // client.on("user-joined", async (user) => {
            //     // Subscribe to a remote user
            //     console.log("3.0 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", user);
            // });
            // client.on("user-unpublished", async (user) => {
            //     console.log("4.0 >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", user);
            // });
            // return () => {
            //     handleLeave();
            // };
        } catch (error) {
            console.error("Error initializing client:", error);
        }
    };
    // const handleLeave = async () => {
    //     try {
    //         await agoraClient.leave();
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [joined, setJoined] = useState(false);

    const playAndPublishLocalVideo = async () => {
        if (!joined || agoraClient === null || agoraClient === undefined) {
            return;
        }
        try {
            let lAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            let lVideoTrack = await AgoraRTC.createCameraVideoTrack();
            lVideoTrack.play("local-stream");
            setLocalAudioTrack(lAudioTrack);
            setLocalVideoTrack(lVideoTrack);
            // // Publish the local audio and video tracks to the channel
            await agoraClient.publish([lAudioTrack, lVideoTrack]);
        } catch (err) {
            console.log(err);
        }
    };

    const stopAndUnPublishLocalVideo = async () => {
        if (localAudioTrack === null || localAudioTrack === undefined) {
            return;
        }
        try {
            // const localContainer = document.getElementById("local-stream");
            localAudioTrack.close();
            localVideoTrack.close();
            await agoraClient.leave();
            initializeAgora();
            // localContainer.textContent = "";

            // // Traverse all remote users
            // client.remoteUsers.forEach((user) => {
            //     // Destroy the dynamically created DIV container
            //     const playerContainer = document.getElementById(user.uid);
            //     playerContainer && playerContainer.remove();
            // });
            // // Leave the channel
            //
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (joined) {
            playAndPublishLocalVideo();
        } else {
            stopAndUnPublishLocalVideo();
        }
    }, [joined]);

    useEffect(() => {
        playAndPublishLocalVideo();
    }, [agoraClient]);

    const [loggedInPlayer, setLoggedInPlayer] = useState(null);
    useEffect(() => {
        if (loggedInPlayer !== null && loggedInPlayer !== undefined) {
            initializeAgora();
        }
    }, [loggedInPlayer]);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [rhsGpTableModel, setRhsGpTableModel] = useState(null);
    const [socketCaller, setSocketCaller] = useState(null);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const faceSuiteUtils = new FaceSuiteUtils();
    const chipsUtils = new ChipsUtils();

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const PrettoSlider = styled(Slider)({
        color: "#52af77",
        height: 8,
        "& .MuiSlider-track": {
            border: "none",
        },
        "& .MuiSlider-thumb": {
            height: 24,
            width: 24,
            backgroundColor: "#fff",
            border: "2px solid currentColor",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                boxShadow: "inherit",
            },
            "&:before": {
                display: "none",
            },
        },
        "& .MuiSlider-valueLabel": {
            lineHeight: 1.2,
            fontSize: 12,
            background: "unset",
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: "50% 50% 50% 0",
            backgroundColor: "#52af77",
            transformOrigin: "bottom left",
            transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
            "&:before": { display: "none" },
            "&.MuiSlider-valueLabelOpen": {
                transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
            },
            "& > *": {
                transform: "rotate(45deg)",
            },
        },
    });
    const funSocketClient = () => {
        var tempSocketClient = new SocketClient({
            successCallBack: (action, responsePacket) => {
                if (action === "MyDetail") {
                    setLoggedInPlayer(responsePacket.playerDetail);
                }
                // if (responsePacket.tableId !== urlParamTableId) {return;}
                if (responsePacket.doubleHandTableId !== urlParamTableId) {
                    return;
                }
                if (action === "TableLog") {
                    return;
                }
                console.log(action, responsePacket);
                if (action === "UpdateTable") {
                    if (responsePacket.tableId.startsWith("L-")) {
                        setGpTableModel(responsePacket.tableModel);
                    } else if (responsePacket.tableId.startsWith("R-")) {
                        setRhsGpTableModel(responsePacket.tableModel);
                    }
                } else if (responsePacket.tableModel !== undefined) {
                    if (responsePacket.tableId.startsWith("L-")) {
                        setGpTableModel(responsePacket.tableModel);
                    } else if (responsePacket.tableId.startsWith("R-")) {
                        setRhsGpTableModel(responsePacket.tableModel);
                    }
                }

                // Result
                // CardDealing
                // FlopOpen
                // TurnTimer
                // TurnOpen
                // RiverOpen
                // GraceTimer
                // ReBuyIn
                // TableLog
            },
            failedCallBack: (action, responseJson) => {},
            onConnectCallBack: () => {
                tempSocketClient.getMyDetail();
                tempSocketClient.getTableDetail("DoubleHandGame", urlParamTableId);
                tempSocketClient.joinTable("DoubleHandGame", urlParamTableId);
            },
        });
        tempSocketClient.setClientIdAndPlayerAuth(urlParamClientId, urlParamUserName, urlParamSecret, urlParamUserType);
        tempSocketClient.initializeSocket();
        setSocketCaller(
            new SocketCaller({
                socketClient: tempSocketClient,
                gameType: "DoubleHandGame",
                tableId: urlParamTableId,
            })
        );
    };
    const [tableLogoUrl, setTableLogoUrl] = useState("");

    const getTableLogo = () => {
        ApiCaller.callGetApi(
            url.MethodWebsiteDetail,
            {},
            (response) => {
                if (response.success) {
                    const res = response.responsePacket;
                    setTableLogoUrl(res.logoIcon);
                }
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };
    useEffect(() => {
        getTableLogo();
        funSocketClient();
    }, []);

    const getLoader = () => {
        return (
            loaderVisible && (
                <div id="loader">
                    <div className="loder-box">
                        <div className="loader-box"></div>
                    </div>
                </div>
            )
        );
    };

    const getHiddenStaticResource = () => {
        return (
            <div style={{ display: "none" }}>
                <img src="gamePlayAssets/img/cards/CARD.png" alt="CARD.png" />
                {faceSuiteUtils.cardSuiteFace[0].map((suite, key) => {
                    return faceSuiteUtils.cardSuiteFace[1].map((face, key) => {
                        var imgSrc = "gamePlayAssets/img/cards/" + suite + "_" + face + ".png";
                        return <img key={suite + "_" + face} src={imgSrc} alt={suite + "_" + face} />;
                    });
                })}
                {chipsUtils.chipsSize[1].map((coin, key) => {
                    var imgSrc = "gamePlayAssets/img/chips/" + coin + ".png";
                    return <img key={coin} src={imgSrc} alt={imgSrc} />;
                })}
            </div>
        );
    };

    const takeStakeOnTakeSeatModel = () => {
        return (
            <div className="modal fade" id="takeStakeOnTakeSeatModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-none" style={{ background: "white", color: "black" }}>
                            Select Buy-In Amount
                        </div>
                        <div className="modal-body" style={{ margin: "30px 15px 0px 15px" }}>
                            Left Hand Buy-In
                            <PrettoSlider
                                valueLabelDisplay="auto"
                                defaultValue={gpTableModel?.gameConfig.minimumBuyIn}
                                min={gpTableModel?.gameConfig.minimumBuyIn}
                                max={gpTableModel?.gameConfig.maximumBuyIn}
                                onChange={handleBuyInSliderChange}
                            />
                            <div className="d-flex justify-content-center">
                                <img className="purchaseChipsModal-chips-img" src="gamePlayAssets/img/poker-chip.png" alt="poker-chip.png" />
                                <p id="selectedBuyInAmount">{gpTableModel?.gameConfig.minimumBuyIn}</p>
                            </div>
                            <hr />
                            Right Hand Buy-In
                            <PrettoSlider
                                valueLabelDisplay="auto"
                                defaultValue={gpTableModel?.gameConfig.minimumBuyIn}
                                min={gpTableModel?.gameConfig.minimumBuyIn}
                                max={gpTableModel?.gameConfig.maximumBuyIn}
                                onChange={handleRhsBuyInSliderChange}
                            />
                            <div className="d-flex justify-content-center">
                                <img className="purchaseChipsModal-chips-img" src="gamePlayAssets/img/poker-chip.png" alt="poker-chip.png" />
                                <p id="selectedRhsBuyInAmount">{gpTableModel?.gameConfig.minimumBuyIn}</p>
                            </div>
                        </div>
                        <div className="text-center mx-2 my-2">
                            <button className="btn btn-light btn-sm mr-3 cursor" data-dismiss="modal">
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm cursor" data-dismiss="modal" onClick={takeSeatAction}>
                                Take Seat
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    var selectedChairIndex = -1;
    const showTakeStakeOnTakeSeatModel = (chairIndex) => {
        $("#selectedBuyInAmount").html(gpTableModel?.gameConfig.minimumBuyIn);
        $("#selectedRhsBuyInAmount").html(gpTableModel?.gameConfig.minimumBuyIn);
        selectedChairIndex = chairIndex;
    };
    const handleBuyInSliderChange = (e) => {
        $("#selectedBuyInAmount").html(e.target.value);
    };
    const handleRhsBuyInSliderChange = (e) => {
        $("#selectedRhsBuyInAmount").html(e.target.value);
    };
    const takeSeatAction = (e) => {
        var buyInAmount = Number($("#selectedBuyInAmount").html());
        var rhsBuyInAmount = Number($("#selectedRhsBuyInAmount").html());
        socketCaller.callTakeSeat(selectedChairIndex, buyInAmount, rhsBuyInAmount);
    };

    var myChairIndex = -1;
    const getMyChairIndex = () => {
        myChairIndex = -1;
        if (gpTableModel === null || gpTableModel === undefined) {
            return;
        }
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel != null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
                break;
            }
        }
    };
    const showHideActionButtons = () => {
        getMyChairIndex();
        if (myChairIndex > -1) {
            // I am on one Chair.
            if (gpTableModel != null && gpTableModel.tableStatus === "Turn") {
                if (gpTableModel.chairs[myChairIndex].playerStatus === "Turn") {
                    // It's My Turn
                    return true;
                }
            }
            if (rhsGpTableModel != null && rhsGpTableModel.tableStatus === "Turn") {
                if (rhsGpTableModel.chairs[myChairIndex].playerStatus === "Turn") {
                    // It's My Turn
                    return true;
                }
            }
        }
        return false;
    };

    const showHideCheckButtons = () => {
        getMyChairIndex();
        if (myChairIndex > -1) {
            // I am on one Chair.
            if (gpTableModel.tableStatus === "Turn") {
                if (gpTableModel.chairs[myChairIndex].playerStatus === "WaitingForTurn") {
                    // It's Not My Turn
                    return true;
                }
            }
            if (rhsGpTableModel.tableStatus === "Turn") {
                if (rhsGpTableModel.chairs[myChairIndex].playerStatus === "WaitingForTurn") {
                    // It's Not My Turn
                    return true;
                }
            }
        }
        return false;
    };

    const handleRaiseSliderChange = (event) => {
        updateRaiseValue(event.target.value);
    };
    const updateRaiseValue = (amount) => {
        $("#raiseAmount").html(chipsUtils.getFormattedChipsTitle(parseFloat(amount)));
    };
    const handleRaiseSliderMinusButton = (event) => {
        var sliderAmount = $("#raiseSlider").val();
        sliderAmount = sliderAmount - gpTableModel.gameConfig.bigBlind;
        var minAmount = parseFloat($("#raiseSlider").attr("min"));
        if (sliderAmount < minAmount) {
            sliderAmount = minAmount;
        }
        $("#raiseSlider").val(sliderAmount);
        updateRaiseValue(sliderAmount);
    };
    const handleRaiseSliderPlusButton = (event) => {
        var sliderAmount = $("#raiseSlider").val();
        sliderAmount = parseFloat(sliderAmount) + gpTableModel.gameConfig.bigBlind;
        var maxAmount = parseFloat($("#raiseSlider").attr("max"));
        if (sliderAmount > maxAmount) {
            sliderAmount = maxAmount;
        }
        $("#raiseSlider").val(sliderAmount);
        updateRaiseValue(sliderAmount);
    };
    const handleRaiseAction = (event) => {
        var raiseAmount = parseFloat($("#raiseSlider").val());
        if (raiseAmount === gpTableModel.chairs[myChairIndex].playerModel.playBalance) {
            socketCaller?.callPlaceBet("AllIn", 0);
        } else {
            socketCaller?.callPlaceBet("Raise", parseFloat(raiseAmount));
        }
    };
    const handleCheckButtonCheck = (event) => {
        var markCheck = event.target.checked;
        $(".checkButton").each(function () {
            $(this).prop("checked", false);
        });
        if (markCheck) {
            $("#" + event.target.id).prop("checked", true);
        }
    };
    const autoAct = () => {
        var foldChecked = $("#Fold").prop("checked");
        if (foldChecked !== undefined && foldChecked) {
            socketCaller?.callPlaceBet("Fold", 0);
            return true;
        }
        var checkChecked = $("#Check").prop("checked");
        if (checkChecked !== undefined && checkChecked) {
            socketCaller?.callPlaceBet("Check", 0);
            return true;
        }
        var callChecked = $("#Call").prop("checked");
        if (callChecked !== undefined && callChecked) {
            socketCaller?.callPlaceBet("Call", 0);
            return true;
        }
        var callAnyChecked = $("#CallAny").prop("checked");
        if (callAnyChecked !== undefined && callAnyChecked) {
            if (gpTableModel.chairs[myChairIndex].chairPotBalance === undefined || gpTableModel.minBetPrice === gpTableModel.chairs[myChairIndex].chairPotBalance) {
                socketCaller?.callPlaceBet("Check", 0);
            } else {
                socketCaller?.callPlaceBet("Call", 0);
            }
            return true;
        }
        return false;
    };

    const lhsActionButtons = () => {
        if (gpTableModel == null || gpTableModel.tableStatus !== "Turn" || gpTableModel.chairs[myChairIndex].playerStatus !== "Turn") {
            return <></>;
        }
        return (
            <>
                {
                    /* Fold Button */
                    gpTableModel.chairs[myChairIndex].foldButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Fold", 0)}>
                                L- Fold
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Check Button */
                    gpTableModel.chairs[myChairIndex].checkButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Check", 0)}>
                                Check
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Call Button */
                    gpTableModel.chairs[myChairIndex].callButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-call text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Call", 0)}>
                                CALL{" "}
                                <b className="" style={{ display: "block" }}>
                                    {" "}
                                    {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.betPrice !== null ? gpTableModel.betPrice : 0)}{" "}
                                </b>
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Raise Button */
                    gpTableModel.chairs[myChairIndex].raiseButton ? (
                        <div className="custam-progress-bar progress-desktop-div">
                            <div className="mr-2" onClick={handleRaiseSliderMinusButton}>
                                <a href="#" className="progres-caunting-btn">
                                    -
                                </a>
                            </div>
                            <div className="mr-2" style={{ width: "20%", overflow: "hidden" }}>
                                <div className="range-container">
                                    <div className="range-track progress">
                                        <div className="range-track-bar progress-bar active progress-bar-primary" ms-css-width='value + "%"' />
                                    </div>
                                    <input
                                        className="range-control w-100"
                                        type="range"
                                        id="raiseSlider"
                                        onChange={handleRaiseSliderChange}
                                        min={gpTableModel.betPrice * 2}
                                        max={gpTableModel.chairs[myChairIndex].playerModel.playBalance}
                                    />
                                </div>
                            </div>
                            <div className="mr-2" onClick={handleRaiseSliderPlusButton}>
                                <a href="#" className="progres-caunting-btn">
                                    {" "}
                                    +{" "}
                                </a>
                            </div>
                            <div>
                                <a href="#" className="btn bg-gradient-raise text-white mr-2" onClick={handleRaiseAction}>
                                    RAISE{" "}
                                    <b className="" style={{ display: "block" }} id="raiseAmount">
                                        {" "}
                                        {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.betPrice !== null ? gpTableModel.betPrice * 2 : 0)}{" "}
                                    </b>
                                </a>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* All In Button */
                    gpTableModel.chairs[myChairIndex].allInButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("AllIn", 0)}>
                                All In
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </>
        );
    };

    const rhsActionButtons = () => {
        if (rhsGpTableModel == null || rhsGpTableModel.tableStatus !== "Turn" || rhsGpTableModel.chairs[myChairIndex].playerStatus !== "Turn") {
            return <></>;
        }
        return (
            <>
                {
                    /* Fold Button */
                    rhsGpTableModel.chairs[myChairIndex].foldButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Fold", 0)}>
                                R- Fold
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Check Button */
                    rhsGpTableModel.chairs[myChairIndex].checkButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Check", 0)}>
                                Check
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Call Button */
                    rhsGpTableModel.chairs[myChairIndex].callButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-call text-white mr-2" onClick={() => socketCaller?.callPlaceBet("Call", 0)}>
                                CALL{" "}
                                <b className="" style={{ display: "block" }}>
                                    {" "}
                                    {chipsUtils.getFormattedChipsTitle(rhsGpTableModel.betPrice !== null ? rhsGpTableModel.betPrice : 0)}{" "}
                                </b>
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* Raise Button */
                    rhsGpTableModel.chairs[myChairIndex].raiseButton ? (
                        <div className="custam-progress-bar progress-desktop-div">
                            <div className="mr-2" onClick={handleRaiseSliderMinusButton}>
                                <a href="#" className="progres-caunting-btn">
                                    -
                                </a>
                            </div>
                            <div className="mr-2" style={{ width: "20%", overflow: "hidden" }}>
                                <div className="range-container">
                                    <div className="range-track progress">
                                        <div className="range-track-bar progress-bar active progress-bar-primary" ms-css-width='value + "%"' />
                                    </div>
                                    <input
                                        className="range-control w-100"
                                        type="range"
                                        id="raiseSlider"
                                        onChange={handleRaiseSliderChange}
                                        min={rhsGpTableModel.betPrice * 2}
                                        max={rhsGpTableModel.chairs[myChairIndex].playerModel.playBalance}
                                    />
                                </div>
                            </div>
                            <div className="mr-2" onClick={handleRaiseSliderPlusButton}>
                                <a href="#" className="progres-caunting-btn">
                                    {" "}
                                    +{" "}
                                </a>
                            </div>
                            <div>
                                <a href="#" className="btn bg-gradient-raise text-white mr-2" onClick={handleRaiseAction}>
                                    RAISE{" "}
                                    <b className="" style={{ display: "block" }} id="raiseAmount">
                                        {" "}
                                        {chipsUtils.getFormattedChipsTitle(rhsGpTableModel.betPrice !== null ? rhsGpTableModel.betPrice * 2 : 0)}{" "}
                                    </b>
                                </a>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
                {
                    /* All In Button */
                    rhsGpTableModel.chairs[myChairIndex].allInButton ? (
                        <div>
                            <a href="#" className="btn bg-gradient-ok text-white mr-2" onClick={() => socketCaller?.callPlaceBet("AllIn", 0)}>
                                All In
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </>
        );
    };

    const actionButtonView = () => {
        if (showHideActionButtons() && !autoAct()) {
            return (
                <div className="footer-div">
                    <div className="row align-items-center m-0">
                        <div className="col-md-12 col-sm-12 col-12 pl-1">
                            <div className="range w-100 row align-items-center justify-content-center " ms-controller="range-primary" style={{ margin: 0 }}>
                                {lhsActionButtons()}
                                {rhsActionButtons()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (showHideCheckButtons() && false) {
            return (
                <div className="footer-div">
                    <div className="row align-items-center m-0">
                        {/* Check Boxes */}
                        <div className="col-md-12">
                            <div className="chekboxdiv">
                                {
                                    /* Fold Check Button */
                                    <div className="custom-control custom-checkbox bg-gradient-ok">
                                        <input type="checkbox" id="Fold" name="Fold" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                        <label className="custom-control-label" htmlFor="Fold">
                                            Fold
                                        </label>
                                    </div>
                                }
                                {gpTableModel.chairs[myChairIndex].chairPotBalance === undefined || gpTableModel.minBetPrice === gpTableModel.chairs[myChairIndex].chairPotBalance ? (
                                    <div className="custom-control custom-checkbox bg-gradient-ok">
                                        <input type="checkbox" id="Check" name="Check" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                        <label className="custom-control-label" htmlFor="Check">
                                            Check
                                        </label>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {gpTableModel.minBetPrice <= gpTableModel.gameConfig.bigBlind ? (
                                    <div className="custom-control custom-checkbox bg-gradient-ok">
                                        <input type="checkbox" id="Call" name="Call" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                        <label className="custom-control-label" htmlFor="Call">
                                            Call
                                        </label>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {
                                    /* gpTableModel.minBetPrice <= gpTableModel.gameConfig.bigBlind ? */
                                    <div className="custom-control custom-checkbox bg-gradient-ok">
                                        <input type="checkbox" id="CallAny" name="CallAny" className="custom-control-input checkButton" onChange={handleCheckButtonCheck} />
                                        <label className="custom-control-label" htmlFor="CallAny">
                                            Call Any
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <div id="wrapper" theme="felt" cardback="orange" layout="row" className="layout-row main">
                {getLoader()}
                {getHiddenStaticResource()}
                <div id="page-content-wrapper" className="main-container layout-column">
                    {/* Header Start */}
                    <HeaderBar loggedInPlayer={loggedInPlayer} socketCaller={socketCaller} tableId={urlParamTableId} gpTableModel={gpTableModel} />
                    {/* Header End */}
                    <div className="main-view flex">
                        <div className="layout-row container1 p-b-100">
                            <div className="table-container layout-align-center-center layout-column flex">
                                <div className="game-table circular-seats">
                                    <div className="innerBorder circular-bets _md md-dance-theme w-100 h-100">
                                        <Table
                                            loggedInPlayer={loggedInPlayer}
                                            socketCaller={socketCaller}
                                            tableId={urlParamTableId}
                                            gpTableModel={gpTableModel}
                                            rhsGpTableModel={rhsGpTableModel}
                                            showTakeStakeOnTakeSeatModel={showTakeStakeOnTakeSeatModel}
                                            tableLogoUrl={tableLogoUrl}
                                        />
                                        {takeStakeOnTakeSeatModel()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Buttons Start */}
                    {actionButtonView()}
                    {/* Footer Buttons End */}
                </div>
            </div>
        </>
    );
};

export default GamePlayDoubleHand;
