import React, { useState, useEffect } from "react";
import Chair from "./ChairDoubleHand";
import Card from "../GamePlay/Card";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";

const TableDoubleHand = (props) => {
    const [loggedInPlayer, setLoggedInPlayer] = useState(null);
    // const [tableId, setTableId] = useState(props.tableId);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [rhsGpTableModel, setRhsGpTableModel] = useState(null);
    const [socketCaller, setSocketCaller] = useState(null);
    const chipsUtils = new ChipsUtils();

    useEffect(() => {
        setLoggedInPlayer(props.loggedInPlayer);
        setSocketCaller(props.socketCaller);
        // setTableId(props.tableId);
        // PreFlop / Flop / Turn / River
        if (props.gpTableModel && props.gpTableModel.tableTurnStatus !== props.gpTableModel.tableTurnStatus) {
            if (props.gpTableModel.tableTurnStatus === "Flop") {
                console.log("Flop OPEN");
            } else if (props.gpTableModel.tableTurnStatus === "Turn") {
                console.log("Turn OPEN");
            } else if (props.gpTableModel.tableTurnStatus === "River") {
                console.log("River OPEN");
            } else {
            }
        } else {
        }
        setGpTableModel(props.gpTableModel);
        setRhsGpTableModel(props.rhsGpTableModel);
    }, [props.loggedInPlayer, props.socketCaller, props.tableId, props.gpTableModel, props.rhsGpTableModel]);

    const getChairs = () => {
        if (gpTableModel === null) {
            return <></>;
        }

        var indexClasses = [];
        if (gpTableModel.gameConfig.maxPlayers === 2) {
            indexClasses = [1, 6];
        } else if (gpTableModel.gameConfig.maxPlayers === 4) {
            indexClasses = [1, 4, 6, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 6) {
            indexClasses = [1, 4, 5, 6, 7, 8];
        } else if (gpTableModel.gameConfig.maxPlayers === 8) {
            indexClasses = [1, 3, 4, 5, 6, 7, 8, 9];
        } else if (gpTableModel.gameConfig.maxPlayers === 10) {
            indexClasses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        }
        // indexClasses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        var updatedChairIndex = [];
        var myChairIndex = -1;
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel !== null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
            }
            updatedChairIndex.push(key);
        }

        // TO Switch Position of Chair Where My Chair is on bottom side.
        if (myChairIndex > 0) {
            updatedChairIndex = [];
            for (let cIndex = myChairIndex; cIndex < gpTableModel.chairs.length; cIndex++) {
                updatedChairIndex.push(gpTableModel.chairs[cIndex].index);
            }
            for (let cIndex = 0; cIndex < myChairIndex; cIndex++) {
                updatedChairIndex.push(gpTableModel.chairs[cIndex].index);
            }
        }
        return (
            <>
                {indexClasses.map((iClass, key) => {
                    return (
                        <Chair
                            key={iClass}
                            indexClass={iClass}
                            loggedInPlayer={loggedInPlayer}
                            chairIndex={key}
                            socketCaller={socketCaller}
                            gpTableModel={gpTableModel}
                            rhsGpTableModel={rhsGpTableModel}
                            gpChairModel={gpTableModel?.chairs[updatedChairIndex[key]]}
                            rhsGpChairModel={rhsGpTableModel?.chairs[updatedChairIndex[key]]}
                            showTakeStakeOnTakeSeatModel={props.showTakeStakeOnTakeSeatModel}
                        />
                    );
                })}
            </>
        );
    };

    const getTableChipsAndPot = () => {
        // if (gpTableModel === null) {
        //     return <></>;
        // }
        // if (gpTableModel.tableStatus === 'Turn' || gpTableModel.tableStatus === 'Result') {
        return (
            <div className="pot layout-align-center-center layout-column lhsPot">
                <div className="custom-chips text-center">
                    <div className="d-flex justify-content-center">{chipsUtils.getChipsStake(gpTableModel !== null && gpTableModel.totalPotBalance !== null ? gpTableModel.totalPotBalance : 0)}</div>
                    <div className="mt-2 text-white">
                        <span>{chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.totalPotBalance !== null ? gpTableModel.totalPotBalance : 0)}</span>
                    </div>
                </div>
                <div className=" align-items-center">
                    <div className="pot-box mr-2 ">
                        <span>TP : {chipsUtils.getFormattedChipsTitle(gpTableModel !== null && gpTableModel.tableChairPotBalance !== null ? gpTableModel.tableChairPotBalance : 0)}</span>
                    </div>
                </div>
            </div>
        );
        // }
        // return <></>;
    };

    const getRhsTableChipsAndPot = () => {
        // if (rhsGpTableModel === null) {
        //     return <></>;
        // }
        // if (rhsGpTableModel.tableStatus === 'Turn' || rhsGpTableModel.tableStatus === 'Result') {
        return (
            <div className="pot layout-align-center-center layout-column rhsPot">
                <div className="custom-chips text-center">
                    <div className="d-flex justify-content-center">
                        {chipsUtils.getChipsStake(rhsGpTableModel !== null && rhsGpTableModel.totalPotBalance !== null ? rhsGpTableModel.totalPotBalance : 0)}
                    </div>
                    <div className="mt-2 text-white">
                        <span>{chipsUtils.getFormattedChipsTitle(rhsGpTableModel !== null && rhsGpTableModel.totalPotBalance !== null ? rhsGpTableModel.totalPotBalance : 0)}</span>
                    </div>
                </div>
                <div className=" align-items-center">
                    <div className="pot-box mr-2 ">
                        <span>TP : {chipsUtils.getFormattedChipsTitle(rhsGpTableModel !== null && rhsGpTableModel.tableChairPotBalance !== null ? rhsGpTableModel.tableChairPotBalance : 0)}</span>
                    </div>
                </div>
            </div>
        );
        // }
        // return <></>;
    };

    const getTableCards = () => {
        return (
            <>
                {[...Array(5)] &&
                    [...Array(5)].map((number, index) => {
                        var cardModel = null;
                        if (gpTableModel !== null && gpTableModel.tableCards !== null) {
                            if (gpTableModel.tableCards[index] !== undefined) {
                                cardModel = gpTableModel.tableCards[index];
                            }
                        }
                        return <Card key={"TblCard_" + index} cardPosition="table" gpTableModel={gpTableModel} gpCardModel={cardModel} />;
                    })}
            </>
        );
    };

    const getRhsTableCards = () => {
        return (
            <>
                {[...Array(5)] &&
                    [...Array(5)].map((number, index) => {
                        var cardModel = null;
                        if (rhsGpTableModel !== null && rhsGpTableModel.tableCards !== null) {
                            if (rhsGpTableModel.tableCards[index] !== undefined) {
                                cardModel = rhsGpTableModel.tableCards[index];
                            }
                        }
                        return <Card key={"TblCard_" + index} cardPosition="table" gpTableModel={gpTableModel} gpCardModel={cardModel} />;
                    })}
            </>
        );
    };

    const getTableCardsOrStatus = () => {
        if (gpTableModel === null) {
            return <></>;
        }
        if (gpTableModel.tableStatus === "Turn" || gpTableModel.tableStatus === "Result") {
            return (
                <>
                    <div className="lhsTableContent layout-align-center-center layout-column">
                        <div className="d-flex">
                            {/* LHS Chips Stake */}
                            {getTableChipsAndPot()}
                            {/* LHS Table Cards */}
                            <div className="community ng-scope layout-align-center-center layout-row center-card lhsCommunity">{getTableCards()}</div>
                        </div>
                        {getResult()}
                    </div>

                    <div style={{ padding: "10px" }}></div>

                    <div className="rhsTableContent layout-align-center-center layout-column">
                        <div className="d-flex">
                            {/* RHS Table Cards */}
                            <div className="community ng-scope layout-align-center-center layout-row center-card rhsCommunity">{getRhsTableCards()}</div>
                            {/* RHS Chips Stake */}
                            {getRhsTableChipsAndPot()}
                        </div>
                        {getRhsResult()}
                    </div>
                </>
            );
        }
        if (gpTableModel.tableStatus === "None") {
            return <div className="tableStatus"></div>;
        }
        if (gpTableModel.tableStatus === "WaitingForPlayer") {
            return <div className="tableStatus">Waiting For PLayers</div>;
        }
        if (gpTableModel.tableStatus === "WaitingForGame") {
            return <div className="tableStatus">Waiting For Game</div>;
        }
        if (gpTableModel.tableStatus === "Toss") {
            return <div className="tableStatus">Dealing TOSS</div>;
        }
        return <></>;
    };

    const getResult = () => {
        return (
            gpTableModel &&
            gpTableModel.tableStatus === "Result" &&
            gpTableModel.tableTurnStatus === "River" &&
            gpTableModel.tableResultContent && (
                <div className="tableResult" style={{ color: "white", padding: "5px 0px 0px 0px", fontSize: "18px" }}>
                    <div>{gpTableModel.tableResultContent}</div>
                </div>
            )
        );
    };

    const getRhsResult = () => {
        return (
            rhsGpTableModel &&
            rhsGpTableModel.tableStatus === "Result" &&
            rhsGpTableModel.tableTurnStatus === "River" &&
            rhsGpTableModel.tableResultContent && (
                <div className="tableResult" style={{ color: "white", padding: "5px 0px 0px 0px", fontSize: "18px" }}>
                    <div>{rhsGpTableModel.tableResultContent}</div>
                </div>
            )
        );
    };

    return (
        <>
            <div className="table-center layout-align-center-center layout-column flex" style={{ position: "relative", zIndex: "1" }}>
                <div className="position-absolute centerLogo text-center" style={{ zIndex: "-1" }}>
                    <img src={props.tableLogoUrl} alt="" />
                </div>
                {/* Table Cards Start */}
                {getTableCardsOrStatus()}
                {/* Table Cards End */}

                {/* Chair List */}
                {getChairs()}
                {/* Chair List */}
            </div>
        </>
    );
};

export default TableDoubleHand;
