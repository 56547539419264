import { Component } from 'react';

class FaceSuiteUtils extends Component {
	constructor(props) {
		super(props);
		this.cardSuiteFace = [
			["CLUB", "DIAMOND", "HEART", "SPADE"],
			[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
		];
	}
	
	getCardFace = (strFace) => {
		// strFace = 2/3/4/5/6/7/8/9/T/J/Q/K/A
		switch (strFace) {
			case 'A': return "1";
			case 'T': return "10";
			case 'J': return "11";
			case 'Q': return "12";
			case 'K': return "13";
			default: return strFace + "";
		}
	}
}

export default FaceSuiteUtils;