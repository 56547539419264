import React, { Component } from 'react';

class ChipsUtils extends Component {

	constructor(props) {
		super(props);
		this.chipsSize = [
			[
				1, 5, 10, 50, 100, 500,
				1000, 5000, 10000, 50000, 100000, 500000,
				1000000, 5000000, 10000000, 50000000, 100000000
			], [
				"1", "5", "10", "50", "100", "500",
				"1K", "5K", "10K", "50K", "100K", "500K",
				"1M", "5M", "10M", "50M", "100M"
			]
		];
	}

	getOtherCurrencyAmount = (funReal, ChipsInOneDollar, amount) => {
		if (funReal === "Real") {
			return (amount * ChipsInOneDollar);
		} else {
			return (amount / ChipsInOneDollar);
		}
	}

	getFormattedChipsTitle = (amountTemp) => {
        let amount = Number(amountTemp)
		if (amount === undefined) {
			return 0;
		}

		var isFloat = Number(amount) === amount && amount % 1 !== 0;
		if(isFloat) {
			amount = amount.toFixed(2);
		} else {
			amount = amount.toFixed(0);
		}
		return amount;
		// if (amount < 1000) {
		// 	return amount;
		// } else if (amount < 100000) {
		// 	return (amount / 1000) + "K";
		// } else {
		// 	return (amount / 1000000) + "M";
		// }
	}

	getChipsStake = (amount) => {
		var chairPot = amount;
		var coinList = [];
		while (chairPot > 0) {
			var lastIndex = 0;
			for (var i = 0; i < this.chipsSize[0].length; i++) {
				if (this.chipsSize[0][i] <= chairPot) {
					lastIndex = i;
				} else {
					break;
				}
			}
			coinList.push(this.chipsSize[1][lastIndex]);
			chairPot = chairPot - this.chipsSize[0][lastIndex];
		}

		var coinListA = [];
		var coinListB = [];
		if (coinList.length > 8) {
			var center = (coinList.length / 2) + 1;
			coinListA = coinList.slice(0, center);
			coinListB = coinList.slice(center, coinList.length);
		} else {
			coinListA = coinList;
		}
		return (
			<>
				<ul className="list-unstyled">
					{
						coinListA.map((coin, key) => {
							var imageSrc = "gamePlayAssets/img/chips/" + coin + ".png";
							return <li key={'A' + key}><img src={imageSrc} alt={coin} /></li>
						})
					}
				</ul>
				<ul className="list-unstyled">
					{
						coinListB.map((coin, key) => {
							var imageSrc = "gamePlayAssets/img/chips/" + coin + ".png";
							return <li key={'B' + key}><img src={imageSrc} alt={coin} /></li>
						})
					}
				</ul>
			</>
		);
	}
}

export default ChipsUtils;