import { Component } from 'react';

class SocketCaller extends Component {
    constructor(props) {
        super(props);
        this.socketClient = props.socketClient;
        this.gameType = props.gameType;
        this.tableId = props.tableId;
    }

    callGetTableDetail = () => {
        this.socketClient.getTableDetail(this.gameType, this.tableId);
    }
    callJoinTable = () => {
        this.socketClient.joinTable(this.gameType, this.tableId);
    }
    callUnJoinTable = () => {
        this.socketClient.unJoinTable(this.gameType, this.tableId);
    }
    callTakeSeat = (chairId, buyInAmount, rhsBuyInAmount) => {
        this.socketClient.takeSeat(this.gameType, this.tableId, chairId, buyInAmount, rhsBuyInAmount);
    }
    callTakeSeatOnBluff = (chairId, buyInAmount, rhsBuyInAmount, bluffGameList, bluffType) => {
        this.socketClient.takeSeatOnBluff(this.gameType, this.tableId, chairId, buyInAmount, rhsBuyInAmount, bluffGameList, bluffType);
    }
    callLeaveSeat = () => {
        this.socketClient.leaveSeat(this.gameType, this.tableId);
    }
    callSitOut = () => {
        this.socketClient.sitOut(this.gameType, this.tableId);
    }
    callSitBack = () => {
        this.socketClient.sitBack(this.gameType, this.tableId);
    }
    callChangeBigBlindStatus = (bigBlindPostStatus) => {
        this.socketClient.changeBigBlindStatus(this.gameType, this.tableId, bigBlindPostStatus);
    }
    callPlaceBet = (chalType, chalAmount, bluffGameId) => {
        this.socketClient.placeBet(this.gameType, this.tableId, chalType, chalAmount, bluffGameId);
    }
    callReBuyIn = (buyInAmount) => {
        this.socketClient.reBuyIn(this.gameType, this.tableId, buyInAmount);
    }
    disconnectSocket = () => {
        console.log("disconnectSocket");
        this.socketClient.closeConnection();
    }
}

export default SocketCaller;