import React from "react";
import { useState, useEffect } from "react";
import Card from "../GamePlay/Card";
import ChipsUtils from "../GamePlay/Utilities/ChipsUtils";
import $ from "jquery";

export const Chair = (props) => {
    const [indexClass, setIndexClass] = useState(null);
    const [chairIndex, setChairIndex] = useState(null);
    const [gpTableModel, setGpTableModel] = useState(null);
    const [gpChairModel, setGpChairModel] = useState(null);
    const [loggedInPlayer, setLoggedInPlayer] = useState(null);

    const chipsUtils = new ChipsUtils();

    useEffect(() => {
        setIndexClass(props.indexClass);
        setChairIndex(props.chairIndex);
        setGpTableModel(props.gpTableModel);
        setGpChairModel(props.gpChairModel);
        setLoggedInPlayer(props.loggedInPlayer);

        let progressElementId = props.gpChairModel.index + "_" + "progressBar";
        if (props.gpTableModel.tableStatus === "Turn") {
            if (props.gpChairModel.playerStatus === "Turn") {
                var timeLeft = props.gpTableModel.tableTurnTimer.countDown;
                var elementWidth = $("#" + progressElementId).width();
                $("#" + progressElementId).show();
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: elementWidth }, 1, "linear");
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, timeLeft * 1000, "linear");
            } else if (props.gpChairModel.playerStatus === "WaitingForTurn") {
                $("#" + progressElementId)
                    .find("div.bar")
                    .stop();
                $("#" + progressElementId)
                    .find("div.bar")
                    .animate({ width: 0 }, 1, "linear");
                $("#" + progressElementId).hide();
            }
        } else {
            $("#" + progressElementId)
                .find("div.bar")
                .stop();
            $("#" + progressElementId)
                .find("div.bar")
                .animate({ width: 0 }, 1, "linear");
            $("#" + progressElementId).hide();
        }
    }, [props.indexClass, props.chairIndex, props.gpTableModel, props.gpChairModel, props.socketCaller, props.loggedInPlayer]);

    const getMyChairIndex = () => {
        let myChairIndex = -1;
        if (gpTableModel === null || gpTableModel === undefined) {
            return -1;
        }
        for (let key = 0; key < gpTableModel.chairs.length; key++) {
            var playerModel = gpTableModel.chairs[key].playerModel;
            if (playerModel != null && playerModel !== undefined && playerModel.playerId === parseInt(loggedInPlayer.playerId)) {
                myChairIndex = key;
                break;
            }
        }
        return myChairIndex;
    };

    const drawTakeSeatButton = () => {
        let button = "";
        if (parseInt(loggedInPlayer.playerId) > 0) {
            button = (
                <button
                    type="button"
                    onClick={() => props.showTakeStakeOnTakeSeatModel(chairIndex)}
                    data-toggle="modal"
                    data-target="#takeStakeOnTakeSeatModel"
                    className="md-fab seat-button md-button md-dance-theme md-ink-ripple layout-align-center-center layout-row margin-0-auto"
                >
                    <span className="">Sit</span>
                    <div className="md-ripple-container"></div>
                </button>
            );
        } else {
            button = (
                <button type="button" className="md-fab seat-button md-button md-dance-theme md-ink-ripple layout-align-center-center layout-row margin-0-auto">
                    <span className="">E</span>
                    <div className="md-ripple-container"></div>
                </button>
            );
        }
        return (
            <>
                <div className="s-button">
                    {button}
                    {gpChairModel.index === gpTableModel.dealerButtonChairId && <button className="tooltip md-fab md-mini d-button">D</button>}
                </div>
            </>
        );
    };

    const drawTossCardsView = () => {
        var showTossCard = false;
        var winnerLooserClass = "";
        if (gpTableModel.tableStatus === "Toss") {
            if (gpChairModel && gpChairModel.tossCard) {
                showTossCard = true;
                winnerLooserClass = gpChairModel.isTossWinner ? "toss-winner " : "toss-looser ";
            }
        }
        return (
            showTossCard && (
                <div className="hand">
                    <div className="d-flex card-hide">
                        <Card key={chairIndex + "_toss_card_"} cardPosition={winnerLooserClass + "chair-toss"} gpTableModel={gpTableModel} gpCardModel={gpChairModel.tossCard} />
                    </div>
                </div>
            )
        );
    };

    const drawMyCardsView = () => {
        // if myCards has cards, show Open Cards, else show fold cards.
        // if table status is Turn then show Fold Cards.
        var inHandCardCount = 2;
        if (gpTableModel.gameConfig.gameTableType === "Omaha") {
            inHandCardCount = 4;
        }
        var showFoldCards = false;
        if (gpTableModel.tableStatus === "CardDealing" || gpTableModel.tableStatus === "Turn" || gpTableModel.tableStatus === "Result") {
            if (
                gpChairModel &&
                !(
                    gpChairModel.playerStatus === "None" ||
                    gpChairModel.playerStatus === "WaitingForGame" ||
                    gpChairModel.playerStatus === "WaitingForBigBlind" ||
                    gpChairModel.playerStatus === "AutoPostBigBlind" ||
                    gpChairModel.playerStatus === "Fold" ||
                    gpChairModel.playerStatus === "Bust"
                )
            ) {
                showFoldCards = true;
            }
        }
        return (
            <div className="hand">
                <div className="d-flex card-hide">
                    {gpChairModel.myCards.length > 0 &&
                        gpChairModel.playerStatus !== "Fold" &&
                        gpChairModel.myCards.map((card, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={gpTableModel} gpCardModel={card} />;
                        })}
                    {/* TODO > Table Status Check Pending */}
                    {showFoldCards &&
                        gpChairModel.myCards.length === 0 &&
                        [...Array(inHandCardCount)].map((number, index) => {
                            return <Card key={chairIndex + "_card_" + index} cardPosition="chair" gpTableModel={gpTableModel} gpCardModel={null} />;
                        })}
                </div>
            </div>
        );
    };

    const drawChairPotAmountAndStake = () => {
        return (
            gpChairModel.chairPotBalance != null &&
            gpChairModel.chairPotBalance > 0 && (
                <div
                    className={"singlehand chips" + indexClass}
                    style={{
                        animationDuration: "1s",
                        animationFillMode: "forwards",
                    }}
                >
                    <div className="d-flex">
                        <div className="custom-chips text-center mr-1">
                            <div className="d-flex justify-content-center">{chipsUtils.getChipsStake(gpChairModel.chairPotBalance)}</div>
                            <div className=" text-white d-flex justify-content-center chipsText" style={{ lineHeight: "28px" }}>
                                <span>{chipsUtils.getFormattedChipsTitle(gpChairModel.chairPotBalance)}</span>
                                {gpTableModel?.gameConfig.funReal === "Real" ? " G" : " T"}
                            </div>
                        </div>
                        <div className="custom-chips text-center ml-1">
                            <div className="d-flex justify-content-center">
                                {chipsUtils.getChipsStake(chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpChairModel.chairPotBalance))}
                            </div>
                            <div className="text-white d-flex justify-content-center chipsText" style={{ lineHeight: "28px" }}>
                                <span>
                                    {chipsUtils.getFormattedChipsTitle(
                                        chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpChairModel.chairPotBalance)
                                    )}
                                </span>
                                {gpTableModel?.gameConfig.funReal === "Real" ? " T" : " G"}
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    const playerHandResult = () => {
        return (
            gpTableModel &&
            gpTableModel.tableStatus === "Result" &&
            gpChairModel &&
            !(
                gpChairModel.playerStatus === "None" ||
                gpChairModel.playerStatus === "WaitingForBigBlind" ||
                gpChairModel.playerStatus === "AutoPostBigBlind" ||
                gpChairModel.playerStatus === "Fold" ||
                gpChairModel.playerStatus === "Bust"
            ) &&
            gpChairModel.pokerHandSuggestion && (
                <a href="#" className="name md-title md-button md-dance-theme player-hand-result d-flex">
                    {gpChairModel.pokerHandSuggestion}
                </a>
            )
        );
    };

    const showPlayerStatusButton = () => {
        var showLeftButton = false;
        var leftButtonClass = "";
        var leftButtonText = gpChairModel.chalStatus;
        if (gpChairModel && gpChairModel.playerModel) {
            if (gpChairModel.playerStatus === "Bust") {
                showLeftButton = true;
                leftButtonClass = "sitOut-bg";
                leftButtonText = "Burst";
            } else if (gpChairModel.playerStatus === "SitOut") {
                showLeftButton = true;
                leftButtonClass = "sitOut-bg";
                leftButtonText = "Sit Out";
            } else if (gpChairModel.playerStatus === "Fold") {
                showLeftButton = true;
                leftButtonClass = "fold-bg";
                leftButtonText = "Fold";
            } else if (gpChairModel.chalStatus === "Check") {
                showLeftButton = true;
                leftButtonClass = "check-bg";
            } else if (gpChairModel.chalStatus === "Call") {
                showLeftButton = true;
                leftButtonClass = "call-bg";
            } else if (gpChairModel.chalStatus === "Raise") {
                showLeftButton = true;
                leftButtonClass = "raise-bg";
            } else if (gpChairModel.chalStatus === "AllIn") {
                showLeftButton = true;
                leftButtonClass = "allin-bg";
            } else if (gpChairModel.chalStatus === "Fold") {
                showLeftButton = true;
                leftButtonClass = "fold-bg";
            }
        }

        var showRightButton = false;
        var rightButtonClass = "";
        var rightButtonText = "";
        if (gpChairModel && gpChairModel.playerModel) {
            if (gpChairModel.playerStatus === "WaitingForBigBlind") {
                showRightButton = true;
                rightButtonClass = "waitingBlindStatus-bg";
                rightButtonText = "Waiting For BB";
            } else if (gpChairModel.playerStatus === "AutoPostBigBlind") {
                showRightButton = true;
                rightButtonClass = "waitingBlindStatus-bg";
                rightButtonText = "Auto Post BB";
            } else if (gpChairModel.leaveSeat) {
                showRightButton = true;
                rightButtonClass = "leaving-bg";
                rightButtonText = "Leaving Chair";
            } else if (gpChairModel.sitOut && gpChairModel.playerStatus !== "SitOut" && gpChairModel.playerStatus !== "Burst") {
                showRightButton = true;
                rightButtonClass = "sittingout-bg";
                rightButtonText = "Sitting Out";
            } else if ((gpChairModel.playerStatus === "SitOut" || gpChairModel.playerStatus === "Burst") && !gpChairModel.sitOut) {
                showRightButton = true;
                rightButtonClass = "sittingback-bg";
                rightButtonText = "Sitting Back";
            }
        }

        return (
            <div className="chair-btn-div">
                {showLeftButton && (
                    <a href="#" className={"btn-sm chair-btn " + leftButtonClass} style={{ float: "left" }}>
                        {leftButtonText}
                    </a>
                )}
                {showRightButton && (
                    <a href="#" className={"btn-sm chair-btn " + rightButtonClass} style={{ float: "right" }}>
                        {rightButtonText}
                    </a>
                )}
            </div>
        );
    };

    const drawPlayerObject = () => {
        let myChairIndex = getMyChairIndex();
        return (
            <div style={{ display: "block" }}>
                {drawTossCardsView()}
                {drawMyCardsView()}
                {drawChairPotAmountAndStake()}
                <div className="profile">
                    <div className=" position-relative ">
                        <div className="profile-box">
                            <img className="profile-img" src={gpChairModel.playerModel.avatar} />
                            {/* You Button */}
                            {/* {gpChairModel.index === myChairIndex && <span className="profile-buler">YOU</span>} */}
                        </div>

                        {gpChairModel.playerModel.playerId + "" === loggedInPlayer.playerId + "" ? (
                            <video id={`local-stream`} className="stream local-stream" autoPlay muted />
                        ) : (
                            <div id={`remote-stream-${gpChairModel.playerModel.playerId}`} className={`stream remote-stream`}></div>
                        )}
                    </div>
                </div>
                {showPlayerStatusButton()}
                <div className=" md-card player md-dance-theme flex align-items-center">
                    <div className="stack md-caption d-flex">
                        <div className="col-6 p-0">
                            {chipsUtils.getFormattedChipsTitle(gpChairModel.playerModel.playBalance)}
                            &nbsp;
                            {gpTableModel?.gameConfig.funReal === "Real" ? "G" : "T"}
                        </div>
                        <div className="col-6 p-0">
                            {chipsUtils.getFormattedChipsTitle(
                                chipsUtils.getOtherCurrencyAmount(gpTableModel.gameConfig.funReal, gpTableModel.gameConfig.ChipsInOneDollar, gpChairModel.playerModel.playBalance)
                            )}
                            &nbsp;
                            {gpTableModel?.gameConfig.funReal === "Real" ? "T" : "G"}
                        </div>
                    </div>
                    <a href="#" className="name md-title md-button md-dance-theme" style={{ width: "100%" }}>
                        {gpChairModel.playerModel.visibleName}
                        {gpChairModel.index === myChairIndex && <span className="profile-buler"> (YOU)</span>}
                    </a>
                    {playerHandResult()}
                    {/* Dealer Button */}
                    {gpChairModel.index === gpTableModel.dealerButtonChairId && <button className="tooltip md-fab md-mini">D</button>}
                    {/* Small Blind Button */}
                    {gpChairModel.index === gpTableModel.smallBlindChairId && <button className="md-fab md-mini btn-sb md-button">SB</button>}
                    {/* Big Blind Button */}
                    {gpChairModel.index === gpTableModel.bigBlindChairId && <button className="md-fab md-mini btn-bb md-button">BB</button>}
                    {/* <a href="#" className="btn btn-check">Check</a> */}
                </div>
                <div className="progressBar" id={gpChairModel.index + "_" + "progressBar"} style={{ animationFillMode: "forwards", display: "none" }}>
                    <div className="bar" />
                </div>
            </div>
        );
    };

    return indexClass != null ? (
        <div className={"seat layout-align-center-center layout-row taken sorted-index" + indexClass}>
            {gpChairModel !== undefined && (gpChairModel.playerModel === undefined || gpChairModel.playerModel === null) ? drawTakeSeatButton() : ""}
            {gpChairModel !== undefined && gpChairModel.playerModel !== undefined && gpChairModel.playerModel !== null ? drawPlayerObject() : ""}
            {gpChairModel === undefined ? drawTakeSeatButton() : ""}
        </div>
    ) : (
        <></>
    );
};

export default Chair;
