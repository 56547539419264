import React from 'react'

const PrivacyPolicyComponent = () => {
  return (
    <>
      <div>
        <section className="main-content">
          <div className="container">
            <div className="text-center pt-5">
              <h2 className="text-white">Privacy Policy</h2>
              <p>Home &gt;Privacy Policy</p>
            </div>
          </div>
        </section>
        <section className="mb-5">
          <div className="container">
            <div className="contant-div px-4 py-5">
              <h3 className="text-white mt-3">TEXAS HOLD'EM Privacy Policy</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <h4 className="text-white">Consent to Privacy Policy</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <h4 className="text-white">
                What information is collected and for what purpose
              </h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <ul className="customul">
                <li>There are positive gaming environment</li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod.
                </li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor
                </li>
                <li>We ipsum dolor sit amet, consectetur adipiscing</li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada.
              </p>
              <h4 className="text-white">
                Your rights in relation to your information
              </h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <h4 className="text-white">Cookies</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
              <h4 className="text-white">Direct Marketing</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum
                est ultricies integer quis. Iaculis urna id volutpat lacus laoreet.
                Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci
                dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce
                ut placerat orci nulla. Tincidunt ornare massa eget egestas purus
                viverra accumsan in nisl.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PrivacyPolicyComponent