export const HTTPS = process.env.REACT_APP_ServerHTTPS;
export const WSS = process.env.REACT_APP_ServerWSS;
export const HOST = process.env.REACT_APP_ServerDOMAIN;
export const PORT = process.env.REACT_APP_ServerPORT;
export const SERVICE = process.env.REACT_APP_ServerSERVICE;

export const BASE_URL_POKER = HTTPS + "://" + HOST + ":" + PORT + "/v1/api/player/";

export const MethodLogin = BASE_URL_POKER + "login";
export const MethodTableList = BASE_URL_POKER + "tableList";
export const MethodWalletBalance = BASE_URL_POKER + "walletBalance";
export const MethodWebsiteDetail = BASE_URL_POKER + "getWebsiteLogoTitle";
export const MethodGetBluffGameList = BASE_URL_POKER + "getBluffGameList";
export const MethodGetMyAvtarList = BASE_URL_POKER + "getMyAvatarList";
export const MethodSetAvatar = BASE_URL_POKER + "setAvatar/" + "{uuid}";
export const MethodGetAvatarListToBuy = BASE_URL_POKER + "avatarListToBuy";
export const MethodBuyAvatar = BASE_URL_POKER + "buyAvatar/" + "{uuid}";
export const MethodGetProfile = BASE_URL_POKER + "getProfile";
export const MethodGetTableId = BASE_URL_POKER + "getTableId";
export const MethodSetTableId = BASE_URL_POKER + "setTableId/" + "{tableId}";
