import axios from 'axios'

class ApiCaller {

    callGetApi(url, headerMap, successCallBack, failedCallBack) {
        axios.get(url, {
            headers: headerMap,
        }).then((result) => {
            if(successCallBack != null) {
                successCallBack(result.data);
            }
        }).catch((error) => {
            if(failedCallBack != null) {
                failedCallBack(error);
            }
        })
    }

    callPostApi(url, data, headerMap, successCallBack, failedCallBack) {
        axios.post(url, data, {
            headers: headerMap,
        }).then((result) => {
            if(successCallBack != null) {
                successCallBack(result.data);
            }
        }).catch((error) => {
            if(failedCallBack != null) {
                failedCallBack(error);
            }
        })
    }

    // loginPlayer(data,Apiname) {
    //     return axios.post(url.LOGIN_URL + Apiname, data, {
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //     });
    // }
}

export default new ApiCaller();