import React from "react";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Dropdown } from "react-bootstrap";
import ApiCaller from "../../Api/ApiCaller";
import * as url from "../../Const";
import { Link } from "react-router-dom";

const NavigationComponent = ({ index }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);
    const [websiteData, setWebsiteData] = useState(null);
    const cookies = new Cookies();

    const handleWalletBalance = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodWalletBalance,
            {
                Authorization: cook !== undefined ? "Basic " + cook : "",
            },
            (response) => {
                setWalletBalance(response.responsePacket);
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };

    const getUserProfile = () => {
        const cook = cookies.get("pokerAuth");
        ApiCaller.callGetApi(
            url.MethodGetProfile,
            {
                Authorization: cook !== undefined ? "Basic " + cook : "",
            },
            (response) => {
                setLoginData(response.responsePacket);
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };
    useEffect(() => {
        getUserProfile();
        handleWalletBalance();
    }, [index]);

    const handleWebsiteDetail = () => {
        ApiCaller.callGetApi(
            url.MethodWebsiteDetail,
            {},
            (response) => {
                if (response.success) {
                    var websiteData = response.responsePacket;
                    let d = new Date();
                    d.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                    cookies.set("webData", websiteData, { path: "/", expires: d });
                    setWebsiteData(response.responsePacket);
                }
            },
            (error) => {
                console.log("FAILED : ", error);
            }
        );
    };

    useEffect(() => {
        if (cookies.get("pokerLoginData") === undefined) {
            setIsLoggedIn(false);
        } else {
            handleWalletBalance();
            setLoginData(cookies.get("pokerLoginData"));
            getUserProfile();
            setIsLoggedIn(true);
        }
        handleWebsiteDetail();
        setWebsiteData(cookies.get("webData"));
    }, []);

    // useEffect(() => {
    //     handleRefresh();
    //   }, [walletBalance]);

    return (
        <>
            <div className="desktopHeader">
                <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
                    <div className="container ">
                        <Link className="navbar-brand" to="/">
                            <img src={websiteData?.logoIcon} style={{ width: "100px" }} alt="Image" />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarResponsive"
                            aria-controls="navbarResponsive"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>

                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link border-gradient-green border-gradient active" to="/holdem-games">
                                        Game
                                    </Link>
                                </li>
                                {isLoggedIn ? (
                                    <li className="nav-item">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" className="walletbtn" id="dropdown-basic">
                                                <img src="assets/img/wallet.png" alt="Image" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu id="walletBalanceList">
                                                <Dropdown.Item href="#/action-1">GCC Coin - {walletBalance?.totalGCCCoin} </Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Test Coin - {walletBalance?.totalTestCoin} </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <Link
                                    className="nav-link border-gradient-green border-gradient "
                                    to="#"
                                >
                                  <img src='assets/img/wallet.png' alt="Image"/>
                                </Link> */}
                                    </li>
                                ) : (
                                    ""
                                )}
                                {isLoggedIn ? (
                                    <li className="nav-item">
                                        <Link className="text-white d-flex align-items-center" style={{ margin: "0 10px" }} to="/profile">
                                            <div>
                                                <img className="userprofile mr-2" style={{ borderRadius: "50%" }} src={loginData?.photoImageUrl !== "" ? loginData?.photoImageUrl : ""} alt="Image" />
                                            </div>
                                            <div className="ellipsis">{loginData?.fullName}</div>
                                        </Link>
                                    </li>
                                ) : (
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link active" to="/login">
                                                Login
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            {isLoggedIn ? (
                <div className="mobileHeader">
                    <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
                        <div className="container">
                            <div>
                                <Link className="navbar-brand" to="/holdem-games">
                                    <img src={websiteData?.logoIcon} style={{ width: "100px" }} alt="Image" />
                                </Link>
                            </div>
                            <div className="position-relative customToggle">
                                <span
                                    className="text-white d-flex align-items-center dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div>
                                        <img className="userprofile mr-2" style={{ borderRadius: "50%" }} src={loginData?.photoImageUrl !== "" ? loginData?.photoImageUrl : ""} alt="Image" />
                                    </div>
                                    <div className="ellipsis">{loginData?.fullName}</div>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to="/profile">
                                        Profile
                                    </Link>
                                    <Link className="dropdown-item" to="/holdem-games">
                                        Game List
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default NavigationComponent;
