import React from 'react';
import { useState, useEffect } from 'react';
import FaceSuiteUtils from './Utilities/FaceSuiteUtils';

const Card = (props) => {
	// Suite = CLUB / DIAMOND / HEART / SPADE
	// Face  = 1/2/3/4/5/6/7/8/9/10/11/12/13

	// const [gpTableModel, setGpTableModel] = useState(null);
	// const [gpCardModel, setGpCardModel] = useState(null);
	const [mdCardClass, setMdCardClass] = useState("card card-line-height");
	const [imgTag, setImgTag] = useState("");
	const [looserCardDiv, setLooserCardDiv] = useState("");

	useEffect(() => {
		// setGpTableModel(props.gpTableModel);
		// setGpCardModel(props.gpCardModel);
		// cardShow={true} cardSuite={card.suit} cardFace={faceSuiteUtils.getCardFace(card.rank)}
		var cardPositionClass = "";
		if(props.cardPosition != null) {
			cardPositionClass = props.cardPosition + "-card";
		}
		var tempMdCardClass = "card card-line-height " + cardPositionClass;
		var tempImgTag = "";
		var cardImgSrc = "gamePlayAssets/img/cards/CARD.png";
		var cardImg = "";
		if (props.gpCardModel != null) {
			var suite = props.gpCardModel.suit;
			var face = new FaceSuiteUtils().getCardFace(props.gpCardModel.rank);
			cardImg = suite + "_" + face
			cardImgSrc = "gamePlayAssets/img/cards/" + cardImg + ".png";
		} else {
			tempMdCardClass += " suit back";
		}

		setLooserCardDiv("");
		if (props.gpTableModel && props.gpTableModel.tableStatus === "Result" &&
			props.gpTableModel.tableTurnStatus === "River") {
			if (props.gpCardModel && props.gpCardModel.winningCard) {
				tempMdCardClass += " winner-card";
			} else {
				setLooserCardDiv(<div className='looser-card'></div>);
			}
		}
		setMdCardClass(tempMdCardClass);

		tempImgTag = <img src={cardImgSrc} alt={cardImg} />;
		setImgTag(tempImgTag);
	}, [props.cardPosition, props.gpTableModel, props.gpCardModel]);

	return (
		<div>
			<md-card class={mdCardClass}>
				<md-card-content class=" ">
					{imgTag}
				</md-card-content>
				{looserCardDiv}
			</md-card>
		</div>
	)
}

export default Card;