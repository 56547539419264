import React from 'react'

const FAQComponent = () => {
    return (
        <>
            <div>
                <section className="custom-main-content pt-85">
                    <div className="container">
                        <div className="text-center pt-85">
                            <h2 className="text-white">Frequently asked Question</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut
                                <br /> labore et dolore magna aliqua. Bibendum est ultricies integer
                                quis.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="container">
                        <div className="row Learn-tabs ">
                            <div className=" col-md-4">
                                <div className="Learn-tabs-ul">
                                    <ul className="nav nav-tabs">
                                        <h6 className="text-white" style={{ fontSize: "14px" }}>
                                            DEPOSIT &amp; WITHDRAWALS
                                        </h6>
                                        <li className="nav-item">
                                            <a
                                                href="#tab1"
                                                className="nav-link active"
                                                data-bs-toggle="tab"
                                            >
                                                How to transfer money wallet to bank
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab2" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab3" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                    </ul>
                                    <hr />
                                    <ul className="nav nav-tabs">
                                        <h6 className="text-white" style={{ fontSize: "14px" }}>
                                            MY ACCOUNT
                                        </h6>
                                        <li className="nav-item">
                                            <a
                                                href="#tab4"
                                                className="nav-link active"
                                                data-bs-toggle="tab"
                                            >
                                                How to transfer money wallet to bank
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab5" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab6" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                    </ul>
                                    <hr />
                                    <ul className="nav nav-tabs">
                                        <h6 className="text-white" style={{ fontSize: "14px" }}>
                                            MY ACCOUNT
                                        </h6>
                                        <li className="nav-item">
                                            <a
                                                href="#tab7"
                                                className="nav-link active"
                                                data-bs-toggle="tab"
                                            >
                                                How to transfer money wallet to bank
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab8" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#tab9" className="nav-link" data-bs-toggle="tab">
                                                How to add money
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className=" col-md-8">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tab1">
                                        <h3 className="text-white">
                                            How to transfer money wallet to bank
                                        </h3>
                                        <div className="custom-bg p-3 mt-3">
                                            <h4 className="text-white">
                                                01. How to transfer money wallet to bank
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Bibendum est ultricies integer quis. Iaculis urna id volutpat
                                                lacus laoreet. Mauris vitae ultricies leo integer malesuada.
                                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu
                                                cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt
                                                ornare
                                            </p>
                                            <div className="row">
                                                <div className=" col-md-6">
                                                    <img src="assets/img/Rectangle.png" width="100%" alt='Rectangle.png' />
                                                </div>
                                                <div className=" col-md-6">
                                                    <img src="assets/img/Rectangle.png" width="100%" alt='Rectangle.png' />
                                                </div>
                                            </div>
                                            <h4 className="text-white mt-3">
                                                01. How to transfer money wallet to bank
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Bibendum est ultricies integer quis. Iaculis urna id volutpat
                                                lacus laoreet. Mauris vitae ultricies leo integer malesuada.
                                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu
                                                cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt
                                                ornare
                                            </p>
                                            <div className="row pt-3">
                                                <div className=" col-md-6">
                                                    <img src="assets/img/Rectangle.png" width="100%" alt='Rectangle.png' />
                                                </div>
                                                <div className=" col-md-6">
                                                    <img src="assets/img/Rectangle.png" width="100%" alt='Rectangle.png' />
                                                </div>
                                            </div>
                                            <p className="mt-3">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Bibendum est ultricies integer quis. Iaculis urna id volutpat
                                                lacus laoreet. Mauris vitae ultricies leo integer malesuada.
                                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu
                                                cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt
                                                ornare
                                            </p>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Bibendum est ultricies integer quis. Iaculis urna id volutpat
                                                lacus laoreet.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab2">
                                        <p>Profile tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab3">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab4">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab5">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab6">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab7">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab8">
                                        <p>Messages tab content ...</p>
                                    </div>
                                    <div className="tab-pane fade" id="tab9">
                                        <p>Messages tab content ...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default FAQComponent