import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import $ from "jquery";
import * as url from "../../Const";
import ApiCaller from "../../Api/ApiCaller";
import toast, { Toaster } from "react-hot-toast";

const IndexComponent = () => {
    const [websiteData, setWebsiteData] = useState(null);

    useEffect(() => {
        const cookies = new Cookies();
        setWebsiteData(cookies.get("webData"));
    }, []);
    const [requestData, setRequestData] = useState({
        emailOrMobile: "",
        password: "",
        rememberMe: false,
    });

    useEffect(() => {
        initPageJs();
    }, []);

    const initPageJs = () => {
        $(".toggle-password").off("click");
        $(".toggle-password").on("click", function (e) {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    const handleLogin = () => {
        if (requestData.emailOrMobile.trim() === "") {
            toast("Email/Mobile is required!!", {
                icon: "🙁",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        } else if (requestData.password.trim() === "") {
            toast("Password is required!!", {
                icon: "🙁",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        } else {
            var requestPacket = {
                username: requestData.emailOrMobile,
                password: requestData.password,
            };
            ApiCaller.callPostApi(
                url.MethodLogin,
                requestPacket,
                {},
                (response) => {
                    if (response.success) {
                        var loginData = response.responsePacket;
                        const cookies = new Cookies();
                        let d = new Date();
                        d.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                        cookies.set("pokerLoginData", loginData, { path: "/", expires: d });
                        var authToken = btoa(loginData.username + ":" + loginData.uniqueKey);
                        const cookies1 = new Cookies();
                        let d1 = new Date();
                        d1.setTime(d.getTime() + 9 * 60 * 60 * 1000);
                        cookies1.set("pokerAuth", authToken, { path: "/", expires: d1 });
                        //debugger;
                        toast("Login Successfully", {
                            icon: "👏",
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                        window.location.href = "/holdem-games";
                    } else {
                        toast(response.message, {
                            icon: "🙁",
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                    }
                },
                (error) => {
                    console.log("FAILED : ", error);
                }
            );
        }
    };

    // const audio = new Audio("gamePlayAssets/audio/test.mp3");
    // audio.play();

    return (
        <>
            <div className="desktopDiv">
                <header className="masthead text-center ">
                    <div className="masthead-content">
                        <div className="container">
                            <h1 className=" mb-0 text-white">{websiteData?.webSiteTitle}</h1>
                            <h6 className="mt-2">
                                The world series of poker is the most prestigious series <br />
                                of poker tournaments in the world
                            </h6>
                            <div className="mt-4">
                                {/* <a className="btn custom-btn text-white" href="#scroll">
                                    Register Account
                                </a> */}
                                {/* <a href="#" className=" btn text-white">
                                    Lean more
                                </a> */}
                            </div>
                            <div className=" mt-5">
                                <img alt="game-table.png" src="assets/img/game-table.png" width="65%" />
                            </div>
                        </div>
                    </div>
                    <div className="bg-circle-1 bg-circle" />
                    <div className="bg-circle-2 bg-circle2" />
                </header>
                {/* Content section 1*/}
                <section id="scroll " className="pb-5 welcome-div ">
                    <div className="container ">
                        <h2 className="text-white text-center">Welcome {websiteData?.webSiteTitle}</h2>
                        <div className="row mt-5">
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="img-box">
                                    <img alt="aboutimg1.png" src="assets/img/aboutimg1.png" />
                                </div>
                                <div className="contant text-left mt-2">
                                    <h5 className="text-white">Know About Us</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <Link to="/about-us" className="btn custom-btn text-white btn-w-h">
                                        About Us
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="img-box">
                                    <img alt="aboutimg2.png" src="assets/img/aboutimg2.png" />
                                </div>
                                <div className="contant text-left mt-2">
                                    <h5 className="text-white">Know About Us</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <Link to="/learn" className="btn custom-btn text-white btn-w-h">
                                        How to Play
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="img-box">
                                    <img alt="aboutimg3.png" src="assets/img/aboutimg3.png" />
                                </div>
                                <div className="contant text-left mt-2">
                                    <h5 className="text-white">Frequently Asked Questions</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <Link to="/faq" className="btn custom-btn text-white btn-w-h">
                                        FAQ
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Content section 3*/}
                <section className="py-5 game-tutorial masthead">
                    <div className=" container">
                        <h2 className="text-white text-center">Game Tutorial</h2>
                        <div className="mt-5 pb-5">
                            <img alt="video-img.png" src="assets/img/video-img.png" width="100%" />
                        </div>
                    </div>
                    <div className="bg-circle-3 bg-circle3" />
                </section>
                <section className="amazing-features">
                    <div className="container">
                        <h2 className="text-white text-center">Amazing Features</h2>
                        <div className="row text-center">
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="material-money-off.svg" src="assets/img/svg/material-money-off.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Join Free</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="safe.svg" src="assets/img/svg/safe.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Safe &amp; Secure</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="Quick.svg" src="assets/img/svg/Quick.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Quick Withdrawals</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="Protection.svg" src="assets/img/svg/Protection.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Player Protection</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="Union.svg" src="assets/img/svg/Union.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Request &amp; Transfer money</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-4 col-sm-6 mb-2">
                                <div className="p-5">
                                    <img alt="Licenced.svg" src="assets/img/svg/Licenced.svg" />
                                    <div className="mt-4">
                                        <h5 className="text-white">Game Integrity</h5>
                                        <p className="">As the world's largest online poker room, we rely on our hard-won, independently verified.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5 Community-div masthead">
                    <div className=" container">
                        <h2 className="text-white text-center">Community</h2>
                        {/* <div className="social-icons">
                                <ul className="icons-list list-unstyled ">
                                <li>
                                    <a href="#">
                                    <img alt="facebook.svg" src="assets/img/icon/facebook.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                    <img alt="twitter.svg" src="assets/img/icon/twitter.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                    <img alt="twitch.svg" src="assets/img/icon/twitch.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                    <img alt="play.svg" src="assets/img/icon/play.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                    <img alt="instagram.svg" src="assets/img/icon/instagram.svg" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                    <img alt="discord.svg" src="assets/img/icon/discord.svg" />
                                    </a>
                                </li>
                                </ul>
                            </div> */}
                        <div className="row mt-5">
                            <div className=" col-md-6 col-sm-6">
                                <div className="Explore-Twitch d-flex ">
                                    <img alt="explore-witch.png" src="assets/img/explore-witch.png" />
                                </div>
                                <h4 className="text-white mt-3">Explore Poker on Twitch</h4>
                            </div>
                            <div className=" col-md-6 col-sm-6">
                                <div className="Ambassadors d-flex ">
                                    <img alt="Ambassadors.png" src="assets/img/Ambassadors.png" />
                                </div>
                                <h4 className="text-white mt-3">Meet our Ambassadors</h4>
                            </div>
                        </div>
                    </div>
                    <div className="bg-circle-3 bg-circle3" />
                </section>
            </div>
            <div className="mobileDiv">
                <section className="mobileSection">
                    <div className="container d-flex align-items-center justify-content-center " style={{ flex: "1" }}>
                        <div className="row">
                            <div className="col-12 mx-auto">
                                <h2 className="text-white text-center">
                                    Welcome to <br />
                                    Gold Clash Poker
                                </h2>
                                <div className="form-div mt-5">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter email or Mobile number"
                                            onChange={(e) =>
                                                setRequestData({
                                                    ...requestData,
                                                    emailOrMobile: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id="password-field"
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="Password"
                                            onChange={(e) =>
                                                setRequestData({
                                                    ...requestData,
                                                    password: e.target.value,
                                                })
                                            }
                                            onKeyPress={handleKeyPress}
                                        />
                                        <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password cursor"></span>
                                    </div>
                                    <div className="form-check form-group mt-4">
                                        <input
                                            className="styled-checkbox"
                                            id="styled-checkbox-1"
                                            type="checkbox"
                                            defaultValue="value1"
                                            onChange={(e) =>
                                                setRequestData({
                                                    ...requestData,
                                                    rememberMe: e.target.checked,
                                                })
                                            }
                                        />
                                        <label htmlFor="styled-checkbox-1">Remember me</label>
                                    </div>
                                    <div className="form-group mt-4">
                                        <button to="" className="btn custom-btn text-white d-block mx-auto" onClick={handleLogin}>
                                            Login Account
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Toaster />
            </div>
        </>
    );
};

export default IndexComponent;
